import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import AdvancedSearch from "../components/AdvancedSearch";
import ReactPaginate from "react-paginate";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addWishList,
  deleteWishList,
  getProperties,
} from "../redux/actionCreator";
import {
  allProperties_count,
  filter_data,
  footer_location_value,
  home_search,
  loading_spinner,
  propertyListsCount,
  property_lists,
  property_type_value,
  thumbnail_path,
  updateRedux,
  user_logged,
} from "../redux/commonReducer";
import { NumericFormat } from "react-number-format";
import { webURL } from "../constants/configuration";
import NoData from "../components/NoData";
import LoadingSpinner from "../components/LoadingSpinner";
import { toast } from "react-toastify";

function AllProperties() {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const allProperties = useSelector(property_lists);
  const listCount = useSelector(propertyListsCount);
  const loading = useSelector(loading_spinner);
  const pageCount = useSelector(allProperties_count);
  const thumbnailPath = useSelector(thumbnail_path);
  const propertyTypeFromFooter = useSelector(property_type_value);
  const filterData = useSelector(filter_data);
  const locationFromfooter = useSelector(footer_location_value);
  const userLogged = useSelector(user_logged)
  const [sortKey, setSortKey] = useState("recently");
  const homeSearch = useSelector(home_search);

  const pagination = useRef();
  const perPage = 25;

  useEffect(() => {
    console.log("123")
    if(!homeSearch && locationFromfooter == "" && propertyTypeFromFooter == "") {
      dispatch(getProperties());
    }
  }, [userLogged]);

  // console.log({locationFromfooter})

  useEffect(() => {
    if (locationFromfooter != "") {
      dispatch(
        updateRedux({
          key: "filter_data",
          value: {
            ...filterData,
            keyword: locationFromfooter,
          },
        })
      );
      let data = {
        keyword: locationFromfooter,
        page: 0,
      };
      dispatch(getProperties(data,()=> {
        scrollToTop()
      }));
    }
  }, [locationFromfooter,userLogged]);

  useEffect(() => {
    if (propertyTypeFromFooter != "") {
      if (propertyTypeFromFooter == "sale") {
        // setFilterData({ ...filterData, type: "sale", active: "1" });
        dispatch(
          updateRedux({
            key: "filter_data",
            value: {
              ...filterData,
              type: "sale",
              active: "1",
            },
          })
        );
        setPage(0);
        let data = {
          buy: "sale",
          page: 0,
        };
        dispatch(getProperties(data,()=> {
          scrollToTop()
        }));
      } else if (propertyTypeFromFooter == "rent") {
        // setFilterData({ ...filterData, type: "rent", active: "2" });
        dispatch(
          updateRedux({
            key: "filter_data",
            value: {
              ...filterData,
              type: "rent",
              active: "2",
            },
          })
        );
        setPage(0);
        let data = {
          buy: "rent",
          page: 0,
        };
        dispatch(getProperties(data,()=> {
          scrollToTop()
        }));
      } else {
        // setFilterData({ ...filterData, type: "premium", active: "3" });
        dispatch(
          updateRedux({
            key: "filter_data",
            value: {
              ...filterData,
              type: "premium",
              active: "3",
            },
          })
        );
        setPage(0);
        dispatch(updateRedux({ key: 'allProperties_count', value: 0 }))
        dispatch(updateRedux({ key: 'property_lists', value:[] }))
        dispatch(updateRedux({ key: 'propertyListsCount', value: "" }))
        let data = {
          buy: "premium",
          page: 0,
        };
        dispatch(getProperties(data,()=> {
          scrollToTop()
        }));
      }
    }
  }, [propertyTypeFromFooter, userLogged]);

  const scrollToTop = () => {
    // window.scrollTo({top: 200});
    if(!window.matchMedia("(min-width: 768px)")?.matches){
      window.scrollTo({top: 400});
      const divElement = document.getElementById("propertySection");
      divElement?.scrollIntoView({ behavior: "smooth" });
    }else {
      window.scrollTo({top: 0});
    }
  }

  const handlePageChange = (e) => {
    setPage(e.selected);
    if (e.selected >= 0) {
      getSearchDataHandler(e.selected, sortKey);
    }
  };
  const sortHandler = (e) => {
    setPage(0);
    setSortKey(e.target.value);
    getSearchDataHandler(page, e.target.value);
  };

  const getDataHandler = (pag) => {
    let data = {
      buy: "",
      keyword: "",
      property_type: "",
      min_price: "",
      max_price: "",
      bedrooms: "",
      bathrooms: "",
      min_size: "",
      max_size: "",
      amenities: [],
      page: pag,
      sort_key: sortKey,
    };
    dispatch(getProperties(data,()=> {
      scrollToTop()
    }));
  };

  const getSearchDataHandler = (pag, sort_key) => {
    console.log({filterData})
    let temp = [];
    if (filterData?.amenities?.length) {
      filterData?.amenities?.forEach((ele) => {
        temp.push(ele._id);
      });
    }
    let data = {
      buy: filterData?.type,
      keyword: filterData?.keyword ?? "",
      property_type: filterData?.propertyType
        ? filterData?.propertyType?._id
        : "",
      min_price: filterData?.minPrice ? Number(filterData?.minPrice) : "",
      max_price: filterData?.maxPrice ? Number(filterData?.maxPrice) : "",
      bedrooms: filterData?.bedrooms ? filterData?.bedrooms?.value : "",
      bathrooms: filterData?.bathrooms ? filterData?.bathrooms?.value : "",
      min_size: filterData?.minSqft ? filterData?.minSqft : "",
      max_size: filterData?.maxSqft ? filterData?.maxSqft : "",
      // amenities: filterData?.amenities ? filterData?.amenities?._id : "",
      amenities: temp?.length ? temp : "",
      page: pag,
      sort_key: sort_key,
    };
    console.log({data})
    dispatch(getProperties(data,()=> {
      scrollToTop()
    }));
  };

  const applyFIlters = () => {
    getSearchDataHandler(0, sortKey);
    setPage(0);
    dispatch(updateRedux({ key: "footer_location_value", value: "" }));
    dispatch(updateRedux({ key: "property_type_value", value: "" }));
    // window.scrollTo(0, 300);
    // document.getElementById("container_properties").scrollIntoView();
    var scrollDiv = document.getElementById("container_properties").offsetTop;
    window.scrollTo({ top: scrollDiv - 220, behavior: 'smooth'});
  };

  const clearFIlters = () => {
    dispatch(
      updateRedux({
        key: "filter_data",
        value: {
          keyword: "",
          active: "",
          type: "",
          propertyType: "",
          minPrice: "",
          maxPrice: "",
          bedrooms: "",
          bathrooms: "",
          minSqft: "",
          maxSqft: "",
          amenities: [],
        },
      })
    );
    dispatch(updateRedux({ key: "footer_location_value", value: "" }));
    dispatch(updateRedux({ key: "property_type_value", value: "" }));
    setPage(0);
    getDataHandler(0);
  };

  const removeWishlistHandler = (id) => {
    if (token != null) {
      let data = {
        property_id: id,
      };
      dispatch(
        deleteWishList(data, () => {
          getSearchDataHandler(page, sortKey);
        })
      );
    } else {
      // toast.error("Please Login", {
      //   position: "bottom-center",
      //   autoClose: 3000,
      // });
      dispatch(updateRedux({ key: 'login_modal_show', value: true }))
    }
  };

  const addWishlistHandler = (id) => {
    if (token != null) {
      let data = {
        property_id: id,
      };
      dispatch(
        addWishList(data, () => {
          getSearchDataHandler(page, sortKey);
        })
      );
    } else {
      // toast.error("Please Login", {
      //   position: "bottom-center",
      //   autoClose: 3000,
      // });
      dispatch(updateRedux({ key: 'login_modal_show', value: true }))
    }
  };

  return (
    (
      <section className="all__properties">
        <div className="properties__bg">
          <div className="container">
            <div className="inner__banner fadeInUp delay1">
              <h1>ALL PROPERTIES</h1>
            </div>
            <AdvancedSearch
              filterData={filterData}
              applyFIlters={applyFIlters}
              clearFIlters={clearFIlters}
              scrollToTop ={scrollToTop}
              setPage = {setPage}
              sortKey={sortKey}
            />
          </div>
        </div>

        <div id="container_properties" className="container properties">
          <div className="filter">
            <div className="sort__by">
              <p>Sort By</p>
              <div className="with-icon">
                <Form.Select
                  aria-label="Default select example"
                  value={sortKey}
                  onChange={(e) => sortHandler(e)}
                  className="custom-select"
                >
                  <option value="recently">Recently Added</option>
                  <option value="high_low">Price high to low</option>
                  <option value="low_high">Price low to high</option>
                </Form.Select>
              </div>
            </div>
            {
              !loading && allProperties?.length
              ?<div className="count">
                <h5>Results {listCount}</h5>
              </div>
              : null
            }
          </div>
          <div id="propertySection"></div>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Row>
                {allProperties?.map((ele, i) => {
                  return (
                    <Col
                      xs={12}
                      sm={6}
                      lg={4}
                      className="justify-content-md-center"
                    >
                      <div className="property__lists">
                        <div className="property__img">
                          <Link to={`${"/property-details/" + ele?.slug}`}>
                            <img
                              src={
                                webURL +
                                thumbnailPath +
                                "/" +
                                ele?.property_thumbnail_image
                              }
                              alt=""
                              className="pointer"
                            />
                          </Link>
                          <div className="property__tag">
                            {/* <div className="tag"><p>{ele?.buy} <span>|</span> Ready</p></div> */}
                            <div className="tag">
                              <p>
                                {ele?.buy == "rent" ? (
                                  "For rent"
                                ) : (
                                  <>
                                    For Sale <span>|</span> Ready
                                  </>
                                )}{" "}
                              </p>
                            </div>
                            <div className="whishlist">
                              {ele?.is_favourite ? (
                                <i
                                  className="bi bi-heart-fill pointer"
                                  onClick={() => removeWishlistHandler(ele._id)}
                                ></i>
                              ) : (
                                <i
                                  className="bi bi-heart pointer"
                                  onClick={() => addWishlistHandler(ele._id)}
                                ></i>
                              )}
                            </div>
                          </div>
                        </div>
                        <Link to={`${"/property-details/" + ele?.slug}`}>
                          <div className="property__title pointer">
                            <h2>{ele?.property_name}</h2>
                            <div className="property__price">
                              {ele?.askprice == "1" ? (
                                <h3 className="ask__price">ask for price</h3>
                              ) : (
                                <h3>
                                  <NumericFormat
                                    value={ele?.price}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"AED "}
                                  />
                                </h3>
                              )}

                              <h6>{ele?.residential}</h6>
                            </div>
                            <div className="property__features">
                              <ul>
                                <li>
                                  {ele?.residential == "commercial" ? (
                                    <>{ele?.pantry} Pantry</>
                                  ) : ele?.bedroom == "0" ||
                                    ele?.bedroom == "Studio" ? (
                                    "Studio"
                                  ) : (
                                    <> {ele?.bedroom} Bedroom</>
                                  )}{" "}
                                </li>
                                <li>{ele?.bathroom} Bathroom</li>
                                <li className="lowercase">
                                  {ele?.sqft} sq.ft.
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </Col>
                  );
                })}
              </Row>
              {allProperties?.length == 0 ? <NoData /> : ""}
            </>
          )}

          {!loading && pageCount > 1 ? (
            <div className="pagination__style2 mb-5">
              <ReactPaginate
                ref={pagination}
                pageCount={pageCount}
                pageRangeDisplayed={4}
                forcePage={page}
                marginPagesDisplayed={1}
                onPageChange={(e) => handlePageChange(e)}
                containerClassName="pagination"
                activeClassName="active"
                pageLinkClassName="page-link"
                breakLinkClassName="page-link"
                nextLinkClassName="page-link"
                previousLinkClassName="page-link"
                pageClassName="page-item"
                breakClassName="page-item"
                nextClassName="page-item"
                previousClassName="page-item"
                previousLabel={<i className="bi bi-chevron-left"></i>}
                nextLabel={<i className="bi bi-chevron-right"></i>}
              />
            </div>
          ) : (
            ""
          )}
        </div>
      </section>
    )
  );
}

export default AllProperties;
