import React, { useState } from "react";
import CountUpWhenVisible from "./CountUpWhenVisible";
import { LazyLoadImage } from "react-lazy-load-image-component";
import appStoreIcon from "../assets/image/appstore.svg";
import playStoreIcon from "../assets/image/playstore.svg";
import facebook from "../assets/image/facebook.svg";
import twitter from "../assets/image/twitter.svg";
import instagram from "../assets/image/instagram.svg";
import linkedin from "../assets/image/linkedin.svg";
import ModalComponent from "./ModalComponent";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import {
  contact_details,
  filter_data,
  home_details,
  updateRedux,
} from "../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import { applyFilter, getProperties } from "../redux/actionCreator";

const FooterSection = () => {
  const [modalActive, setModalActive] = useState(false);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const details = useSelector(home_details);
  const contactDetails = useSelector(contact_details);
  const filterData = useSelector(filter_data);

  const propertyHandler = (type) => {
    dispatch(updateRedux({ key: "footer_location_value", value: '' }));
    dispatch(updateRedux({ key: "property_type_value", value: type }));
    navigate("/all-properties");
    window.scrollTo(120, 120);
  };

  const locationHandler = (value) => {
    console.log('locationhandler', value)
    dispatch(updateRedux({ key: "footer_location_value", value: value }));
    dispatch(updateRedux({ key: "property_type_value", value: '' }));
    navigate("/all-properties");
    window.scrollTo(120, 120);
  };

  const formatMobileNumber = (number) => {
    return `${number?.slice(0, 2)} ${number?.slice(2, 5)} ${number?.slice(5, 9)}`;
  };

  const formatLandNumber = (number) => {
    return `${number?.slice(0, 1)} ${number?.slice(1, 4)} ${number?.slice(4, 8)}`;
  };


  return (
    <>
      <a
        target="_blank"
        href={`https://wa.me/+971${contactDetails?.mobile}?text=Dear PRIMA LUXURY,\n I am interested in one of your properties. I would appreciate if you can get back to me as soon as possible.`}
        className="float"
      >
        <i className="bi bi-whatsapp"></i>
      </a>
      <div className="footer-section">
        <div className="number-container">
          <div className="numbers-only-container">
            <div className="first-number">
              <b className="number-heading">
                <CountUpWhenVisible end={details?.property_count} /> +
              </b>
              <br />
              <b className="number-sub-heading">{details?.property_label}</b>
            </div>
            <div>
              <b className="number-heading">
                <CountUpWhenVisible end={details?.happy_client_count} /> +
              </b>
              <br />
              <b className="number-sub-heading">
                {details?.happy_client_label}
              </b>
            </div>
            <div>
              <b className="number-heading">
                <CountUpWhenVisible end={details?.location_count} /> +
              </b>
              <br />
              <b className="number-sub-heading">
                {details?.location_count_label}
              </b>
            </div>
            <a
              href={details?.android_app_url}
              className="pointer"
              target="_blank"
            >
              {/* <LazyLoadImage src={playStoreIcon} className="playstore-web" /> */}
            </a>
          </div>
          {/* <div className="image-container">
            <a
              href={details?.android_app_url}
              className="pointer"
              target="_blank"
            >
              <LazyLoadImage src={playStoreIcon} className="playstore-mobile" />
            </a>
            <a href={details?.ios_app_url} className="pointer" target="_blank">
              <LazyLoadImage src={appStoreIcon} className="appstore-image" />
            </a>
          </div> */}
        </div>
        <div className="horizontal-line-footer"></div>
        <div className="link-container">
          <div className="first-link-section">
            <div className="single-link-section first-link">
              <b className="link-heading">Property Locations</b>
              <div className="location__lists">
                {details?.locations?.map((ele, i) => {
                  return (
                    <b
                      className="link-child"
                      onClick={() => locationHandler(ele?.location_name)}
                    >
                      {ele?.location_name}
                    </b>
                  );
                })}
                {/* <Link to='/all-properties'><b className='link-child'>Za'beel</b></Link>
                            <Link to='/all-properties'><b className='link-child'>Palm Jumeirah</b></Link>
                            <Link to='/all-properties'><b className='link-child'>Zabeel First</b></Link>
                            <Link to='/all-properties'><b className='link-child'>Downtown Dubai</b></Link>
                            <Link to='/all-properties'><b className='link-child'>Dubai Hills</b></Link>
                            <Link to='/all-properties'><b className='link-child'>Dubai Creek Harbour</b></Link>
                            <Link to='/all-properties'><b className='link-child'>Emirates Hills</b></Link>
                            <Link to='/all-properties'><b className='link-child'>Al Barari</b></Link>
                            <Link to='/all-properties'><b className='link-child'>Arabian Ranches</b></Link>
                            <Link to='/all-properties'><b className='link-child'>DIFC</b></Link>
                            <Link to='/all-properties'><b className='link-child'>Downtown Dubai</b></Link>
                            <Link to='/all-properties'><b className='link-child'>Dubai Hills</b></Link>
                            <Link to='/all-properties'><b className='link-child'>Dubai Marina</b></Link> */}
                <Link
                  to="/all-properties"
                  state={{}}
                  onClick={() => {
                    window.scrollTo(120, 120)
                    dispatch(updateRedux({ key: "footer_location_value", value: '' }));
                    dispatch(updateRedux({ key: "property_type_value", value: '' }));
                    dispatch(
                      updateRedux({
                        key: "filter_data",
                        value: {
                          keyword: "",
                          active: "",
                          type: "",
                          propertyType: "",
                          minPrice: "",
                          maxPrice: "",
                          bedrooms: "",
                          bathrooms: "",
                          minSqft: "",
                          maxSqft: "",
                          amenities: [],
                        },
                      })
                    );
                    dispatch(getProperties());
                  }}
                  style={{ display: 'flex', justifyContent: "flex-start" }}
                >
                  <b className="link-child">View All</b>
                </Link>
              </div>
            </div>
            <div className="single-link-section">
              <b className="link-heading">Company</b>
              <Link to="/about">
                <b className="link-child">About Us</b>
              </Link>
              <HashLink to="/about#chairman">
                <b className="link-child">Chairman's Message</b>
              </HashLink>
              <Link to="/guide">
                <b className="link-child">Guide</b>
              </Link>
              <Link to="/teams">
                <b className="link-child">The Team</b>
              </Link>
              <Link to="/join-team">
                <b className="link-child">Join Our Team</b>
              </Link>
              <Link to="/contact">
                <b className="link-child">Contact</b>
              </Link>
            </div>
            <div className="single-link-section">
              <b className="link-heading">Properties</b>
              {/* <Link to='/all-properties' onClick={()=>propertyHandler('premium')}><b className='link-child'>Premium</b></Link>
                        <Link to='/all-properties' onClick={()=>propertyHandler('sale')}><b className='link-child'>Buy</b></Link>
                        <Link to='/all-properties' onClick={()=>propertyHandler('rent')}><b className='link-child'>Rent</b></Link> */}
              <b
                className="link-child"
                onClick={() => propertyHandler("premium")}
              >
                Premium
              </b>
              <b className="link-child" onClick={() => propertyHandler("sale")}>
                Sale
              </b>
              <b className="link-child" onClick={() => propertyHandler("rent")}>
                Rent
              </b>
              <Link
                to="/all-properties"
                onClick={() => {
                  dispatch(updateRedux({ key: "property_type_value", value: '' }));
                  dispatch(updateRedux({ key: "footer_location_value", value: '' }));
                  dispatch(
                    updateRedux({
                      key: "filter_data",
                      value: {
                        keyword: "",
                        active: "",
                        type: "",
                        propertyType: "",
                        minPrice: "",
                        maxPrice: "",
                        bedrooms: "",
                        bathrooms: "",
                        minSqft: "",
                        maxSqft: "",
                        amenities: [],
                      },
                    })
                  );
                  dispatch(getProperties());
                  window.scrollTo(120, 120)
                }}
              >
                <b className="link-child">All Properties</b>
              </Link>
            </div>

            <div className="single-link-section w-200">
              <b className="link-heading">Contact</b>
              <b
                className="link-child mb-1"
                dangerouslySetInnerHTML={{ __html: contactDetails?.address }}
              ></b>
              <b className="link-child-medium mb-1 mt-3">
                <a href={`tel:971${contactDetails?.telephone}`}>
                  T : {"+971 " + formatLandNumber(contactDetails?.telephone)}
                </a>
              </b>
              <b className="link-child-medium mb-1">
                <a
                  href={`https://wa.me/971${contactDetails?.mobile}`}
                  target="_blank"
                >
                  M : {"+971 " + formatMobileNumber(contactDetails?.mobile)}
                </a>
              </b>
              <b className="link-child-medium">
                {" "}
                <a href={`mailto:${contactDetails?.email}`}>
                  Email : {contactDetails?.email}{" "}
                </a>
              </b>
            </div>
          </div>

          <div className="second-link-section">
            <div
              className="list-property-button"
              onClick={() => setModalActive(true)}
            >
              LIST YOUR PROPERTY
            </div>
          </div>
        </div>
        <div className="horizontal-line-footer"></div>
        <div className="terms-container">
          <div className="policy-terms-section">
            <div
              className="terms-text"
              onClick={() => navigation("/terms_and_conditions")}
            >
              Terms of Use
            </div>
            <div className="vertical-line"></div>
            <div
              className="terms-text"
              onClick={() => navigation("/privacy_policy")}
            >
              Privacy Policy
            </div>
          </div>
          <div className="social-media-links">
            <a target="_blank" href={details?.facebook}>
              <img src={facebook} width={29} className="social-media" />
            </a>
            <a target="_blank" href={details?.twitter} style={{ display: 'flex', }}>
              <img src={twitter} height={20} className="social-media" />
            </a>
            <a target="_blank" href={details?.instagram}>
              <img src={instagram} width={30} className="social-media" />
            </a>
            <a target="_blank" href={details?.linkedin}>
              <img src={linkedin} width={30} className="social-media" />
            </a>
          </div>
        </div>
        <ModalComponent
          modalActive={modalActive}
          setModalActive={setModalActive}
        />
      </div>
    </>
  );
};

export default FooterSection;
