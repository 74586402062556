import React, { useEffect, useState } from "react";
import { GoogleMap, Marker, OverlayView } from "react-google-maps";
import withScriptjs from "react-google-maps/lib/withScriptjs";
import withGoogleMap from "react-google-maps/lib/withGoogleMap";
import MapPropertyCard from "./MapPropertyCard";
import { useSelector } from "react-redux";
import {
  location_property_details,
  thumbnail_path,
} from "../redux/commonReducer";
import { useNavigate } from "react-router";

const mapStyles = [
  {
    featureType: "all",
    elementType: "all",
    stylers: [
      { saturation: -90 }, // <-- THIS
    ],
  },
];

const getPixelPositionOffset = (width, height) => ({
  x: -(width / 2),
  y: -(height / 2),
});

const MapComponent = withScriptjs(
  withGoogleMap((props) => {
    const navigate = useNavigate();
    const locationProperties = useSelector(location_property_details);
    const thumbnailPath = useSelector(thumbnail_path);
    const [activePosition, setActivePosition] = useState({});

    useEffect(()=> {
      if(locationProperties?.length) {
        setActivePosition({
          lat: locationProperties[0]?.latitude, lng: locationProperties[0]?.longitude,
        })
      }
    },[locationProperties])

    console.log(activePosition, "123");
    return (
      <GoogleMap
        defaultZoom={8}
        center={{
          // lat: 25.1003,
          // lng: 55.2458
          //   lat: 1.0,
          //   lng: -1.21,
          lat: 25.2048,
          lng: 55.2708,
        }}
        options={{
          styles: mapStyles,
        }}
      >
        {locationProperties?.length && locationProperties?.map((ele, i) => {
          return (
            <>
            <OverlayView
              key={i}
              position={{
                lat: ele?.latitude,
                lng: ele?.longitude,
              }}
              mapPaneName={OverlayView?.OVERLAY_MOUSE_TARGET ?? ""}
              getPixelPositionOffset={getPixelPositionOffset}
            >
              <MapPropertyCard
                position={{
                  lat: ele?.latitude,
                  lng: ele?.longitude,
                }}
                activePosition={activePosition}
                setActivePosition={setActivePosition}
                data={ele}
                thumbnailPath={thumbnailPath}
                navigate={navigate}
              />
            </OverlayView>
            </>
          );
        })} 
        {/* <OverlayView
            position={{
                lat: 25.0903,
                lng: 55.2398
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
        >
            <MapPropertyCard
                position={{
                    lat: 25.0903, lng: 55.2398
                }}
                activePosition={activePosition}
                setActivePosition={setActivePosition}
            />
        </OverlayView>
        <OverlayView
            position={{
                lat: 25.0903,
                lng: 55.2598
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
        >
            <MapPropertyCard
                position={{
                    lat: 25.0903,
                    lng: 55.2598
                }}
                activePosition={activePosition}
                setActivePosition={setActivePosition}
            />
        </OverlayView>
        <OverlayView
            position={{
                lat: 25.0903,
                lng: 55.2798
            }}
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            getPixelPositionOffset={getPixelPositionOffset}
        >
            <MapPropertyCard
                position={{
                    lat: 25.0903,
                    lng: 55.2798
                }}
                activePosition={activePosition}
                setActivePosition={setActivePosition}
            />
        </OverlayView> */}
      </GoogleMap>
    );
  })
);

export default MapComponent;
