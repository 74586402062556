import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
    name: 'commonReducer',
    initialState: {
        value: true,
        general_path : "general",
        gallery_path : "property_gallery",
        thumbnail_path : "property_thumbnail_images",
        banner_path : "property_banner_images",
        floorplan_path : "property_floor_plans",
        brochure_path : "property_brochure",
        guide_path : "guide",
        team_path : "team",
        home_loading : false,
        otp_verified : false,
        register_state : false,
        otp_state : false,
        wishListPageCount : 0,
        property_type_value: "",
        filter_data : {
            keyword: "",
            active: "",
            type: "",
            propertyType: "",
            minPrice: "",
            maxPrice: "",
            bedrooms: "",
            bathrooms: "",
            minSqft: "",
            maxSqft: "",
            amenities: [],
        },
        home_search : false,
        forgot_password : false,
        footer_location_value : "",
        property_is_favorite : false,
        user_email : "",
        user_logged : false,
        property_lists: []
    },
    reducers: {
        updateRedux: (state, data) => {
            state[data.payload.key] = data.payload.value
        },
    },
})
export const { updateRedux } = commonSlice.actions;


export const otp_verified = (state) => state.commonReducer.otp_verified;
export const otp_state = (state) => state.commonReducer.otp_state;
export const register_state = (state) => state.commonReducer.register_state;
export const loading_spinner = (state) => state.commonReducer.loading_spinner;
export const property_lists = (state) => state.commonReducer.property_lists;
export const propertyListsCount = (state) => state.commonReducer.propertyListsCount;
export const property_details = (state) => state.commonReducer.property_details;
export const guide_lists = (state) => state.commonReducer.guide_lists;
export const guide_details = (state) => state.commonReducer.guide_details;
export const guide_pageStatus = (state) => state.commonReducer.guide_pageStatus;
export const guide_pageCount = (state) => state.commonReducer.guide_pageCount;
export const similar_properties = (state) => state.commonReducer.similar_properties;
export const property_types = (state) => state.commonReducer.property_types;
export const amenities_lists = (state) => state.commonReducer.amenities_lists;
export const amenities_lists_for_filter = (state) => state.commonReducer.amenities_lists_for_filter;
export const team_lists = (state) => state.commonReducer.team_lists;
export const team_pageCount = (state) => state.commonReducer.team_pageCount;
export const contact_details = (state) => state.commonReducer.contact_details;
export const privacy_details = (state) => state.commonReducer.privacy_details;
export const terms_details = (state) => state.commonReducer.terms_details;
export const about_details = (state) => state.commonReducer.about_details;
export const allProperties_count = (state) => state.commonReducer.allProperties_count;
export const career_lists = (state) => state.commonReducer.career_lists;
export const career_details = (state) => state.commonReducer.career_details;
export const home_details = (state) => state.commonReducer.home_details;
export const home_premium = (state) => state.commonReducer.home_premium;
export const home_loading = (state) => state.commonReducer.home_loading;
export const general_path = (state) => state.commonReducer.general_path;
export const gallery_path = (state) => state.commonReducer.gallery_path;
export const banner_path = (state) => state.commonReducer.banner_path;
export const thumbnail_path = (state) => state.commonReducer.thumbnail_path;
export const floorplan_path = (state) => state.commonReducer.floorplan_path;
export const brochure_path = (state) => state.commonReducer.brochure_path;
export const general_details = (state) => state.commonReducer.general_details;
export const guide_path = (state) => state.commonReducer.guide_path;
export const wishListCount = (state) => state.commonReducer.wishListCount;
export const wishListData = (state) => state.commonReducer.wishListData;
export const wishListPageCount = (state) => state.commonReducer.wishListPageCount;
export const property_type_value = (state) => state.commonReducer.property_type_value;
export const jobtitles = (state) => state.commonReducer.jobtitles;
export const filter_data = (state) => state.commonReducer.filter_data;
export const home_search = (state) => state.commonReducer.home_search;

export const searchItems_sale = (state) => state.commonReducer.searchItems_sale;
export const searchItems_rent = (state) => state.commonReducer.searchItems_rent;
export const searchItems_guide = (state) => state.commonReducer.searchItems_guide;
export const searchItems_team = (state) => state.commonReducer.searchItems_team;
export const about_images = (state) => state.commonReducer.about_images;
export const searchItems_career = (state) => state.commonReducer.searchItems_career;

export const forgot_password = (state) => state.commonReducer.forgot_password;

export const location_details = (state) => state.commonReducer.location_details;
export const location_property_details = (state) => state.commonReducer.location_property_details;

export const footer_location_value = (state) => state.commonReducer.footer_location_value;
export const detail_favourite = (state) => state.commonReducer.detail_favourite;
export const property_is_favorite = (state) => state.commonReducer.property_is_favorite;
export const user_email = (state) => state.commonReducer.user_email;
export const user_logged = (state) => state.commonReducer.user_logged;

export const advanced_search = (state) => state.commonReducer.advanced_search;

export const login_loading = (state) => state.commonReducer.login_loading;
export const forgot_loading = (state) => state.commonReducer.forgot_loading;
export const register_loading = (state) => state.commonReducer.register_loading;
export const reset_loading = (state) => state.commonReducer.reset_loading;
export const join_team_loading = (state) => state.commonReducer.join_team_loading;
export const contact_loading = (state) => state.commonReducer.contact_loading;
export const list_property_loading = (state) => state.commonReducer.list_property_loading;
export const register_interest_loading = (state) => state.commonReducer.register_interest_loading;

export const login_modal_show = (state) => state.commonReducer.login_modal_show;



export default commonSlice.reducer;