import React from "react";
import { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import posterImage from "../assets/image/posterimage.png";
import AOS from "aos";
import "aos/dist/aos.css";
import "../App.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Header from "./Header";
import AdvancedSearchHome from "./AdvancedSearchHome";
import { webURL } from "../constants/configuration";
import { advanced_search, filter_data, general_path, updateRedux } from "../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import AdvancedSearch from "./AdvancedSearch";
import { useEffect } from "react";

const MainSection = ({ details }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState("buy");
  const [advanceSearch, setAdvanceSearch] = useState(false);
  const [menuOpen, setmenuOpen] = useState(false);

  const generalPath = useSelector(general_path);
  const filterData = useSelector(filter_data);
  const isAdvancedSearchActive = useSelector(advanced_search)

  const menuOpenHandler = () => {
    setmenuOpen(!menuOpen);
  };

  useEffect(() => {
    dispatch(
      updateRedux({
        key: "filter_data",
        value: {
          keyword: "",
          active: "",
          type: "",
          propertyType: "",
          minPrice: "",
          maxPrice: "",
          bedrooms: "",
          bathrooms: "",
          minSqft: "",
          maxSqft: "",
          amenities: [],
        },
      })
    );
    dispatch(updateRedux({ key: "home_search", value: false }));
  }, []);

  const applyFIlters = () => {
    dispatch(updateRedux({ key: "home_search", value: true }));
    navigate("/all-properties");
  };

  const clearFIlters = () => {
    dispatch(
      updateRedux({
        key: "filter_data",
        value: {
          keyword: "",
          active: "",
          type: "",
          propertyType: "",
          minPrice: "",
          maxPrice: "",
          bedrooms: "",
          bathrooms: "",
          minSqft: "",
          maxSqft: "",
          amenities: [],
        },
      })
    );
    dispatch(updateRedux({ key: "home_search", value: false }));
  };


  const filename = details?.banner_image?.split('.').pop()
  return (
    <div className={`main-content ${isAdvancedSearchActive ? 'increasedMainSectionHeight': ''}`}>
      <Header />
      <video
        id="video-background"
        className="videoTag"
        autoPlay
        loop
        muted
        poster={filename == 'mp4' ? posterImage : webURL + generalPath + "/" + details?.banner_image}
      >
        <source
          src={webURL + generalPath + "/" + details?.banner_image}
          type="video/mp4"
        />
      </video>
      <div className="container">
        <div className="row justify-content-center">
          <div className="banner__caption">
            <h1>{details?.banner_title}</h1>
            <h4>{details?.banner_subtitle}</h4>
            <AdvancedSearchHome
              filterData={filterData}
              applyFIlters={applyFIlters}
              clearFIlters={clearFIlters}
            />
            {/* <AdvancedSearch
              filterData={filterData}
              applyFIlters={applyFIlters}
              clearFIlters={clearFIlters}
            /> */}
          </div>
        </div>
      </div>

      <div
        className={
          menuOpen ? "social_links social-media-text" : "social-media-text"
        }
      >
        <a target="_blank" href={details?.facebook}>
          <span className="social-media-text-link">Facebook</span>
        </a>
        <div className="line"></div>
        <a target="_blank" href={details?.twitter}>
          <span className="social-media-text-link">Twitter</span>
        </a>
        <div className="line"></div>
        <a target="_blank" href={details?.instagram}>
          <span className="social-media-text-link">Instagram</span>
        </a>
        <div className="line"></div>
        <a target="_blank" href={details?.linkedin}>
          <span className="social-media-text-link">Linkedin</span>
        </a>
      </div>
    </div>
  );
};

export default MainSection;
