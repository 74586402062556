import React from 'react'
import CountUp from 'react-countup'
import ReactVisibilitySensor from 'react-visibility-sensor'

const CountUpWhenVisible = ({end}) => {
    return (
        <CountUp end={end} redraw={true}>
            {({ countUpRef, start }) => (
                <ReactVisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                </ReactVisibilitySensor>
            )}
        </CountUp>
    )
}

export default CountUpWhenVisible