import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {useNavigate } from "react-router-dom";
import { registerForm} from "../redux/actionCreator";
import { useForm } from "react-hook-form";
import { general_details, register_loading, updateRedux } from "../redux/commonReducer";

function RegisterForm({ show, setShow }) {

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const data = useSelector(general_details);
  const loading = useSelector(register_loading)

  const onSubmit = (data) => {
    console.log(data, "data");
    let value = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      phone: data?.phone,
      password: data?.password,
      cpassword: data?.cPassword,
    };
    dispatch(
      registerForm(value, () => {
       setShow(false);
       dispatch(updateRedux({ key: 'login_modal_show', value: false }))
      })
    );
  };

  
  const loginHandler = () => {
    dispatch(updateRedux({ key: 'register_state', value: false }))
    dispatch(updateRedux({ key: 'forgot_password', value: false }))
  }

  const termsNavigateHandler = () => {
    setShow(false);
    dispatch(updateRedux({ key: 'login_modal_show', value: false }))
    dispatch(updateRedux({ key: 'register_state', value: false }))
    dispatch(updateRedux({ key: 'forgot_password', value: false }))
    navigate("/terms_and_conditions")
  }
  
  return (
    <div className="prima__login fadeInUp delay1">
      <div className="inner__banner">
        <h1>{data?.register_title}</h1>
        <h6
          dangerouslySetInnerHTML={{
            __html: data?.register_tagline,
          }}
        ></h6>
      </div>
      <div className="forms">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-6 mb-3">
              <input
                type="text"
                // autoComplete="on"
                className="form-control"
                placeholder="First Name"
                {...register("first_name", {
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z ]*$/,
                    message: "First name should have only alphabets",
                  },
                })}
                aria-invalid={errors.first_name ? "true" : "false"}
              />
              {errors.first_name && (
                <span className="error">
                  {errors.first_name?.message != ""
                    ? errors.first_name?.message
                    : "This field is required"}
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Last Name"
                {...register("last_name", {
                  required: true,
                  pattern: {
                    value: /^[a-zA-Z ]*$/,
                    message: "Last name should have only alphabets",
                  },
                })}
                aria-invalid={errors.last_name ? "true" : "false"}
              />
              {errors.last_name && (
                <span className="error">
                  {errors.last_name?.message != ""
                    ? errors.last_name?.message
                    : "This field is required"}
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                {...register("email", {
                  required: true,
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
                    message: "Entered value does not match email format",
                  },
                })}
                aria-invalid={errors.email ? "true" : "false"}
              />
              {errors.email && (
                <span className="error">
                  {errors.email?.message != ""
                    ? errors.email?.message
                    : "This field is required"}
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <input
                type="text"
                className="form-control"
                placeholder="Phone"
                {...register("phone", {
                  required: true,
                  pattern: {
                    value: /^[0-9]{9,13}$/,
                    message: "Invalid Phone Number (Phone field should have numbers only)",
                  },
                })}
                aria-invalid={errors.phone ? "true" : "false"}
              />
              {errors.phone && (
                <span className="error">
                  {errors.phone?.message != ""
                    ? errors.phone?.message
                    : "This field is required"}
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Password"
                {...register("password", {
                  required: true,
                  pattern: {
                    value:
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&,._])[A-Za-z\d#@$!%*?&,._]{8,}$/,
                    message:
                      "Password should have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (Accepted Characters are #@$!%*?&,_.)",
                  },
                })}
                aria-invalid={errors.password ? "true" : "false"}
              />
              {errors.password && (
                <span className="error">
                  {errors.password?.message != ""
                    ? errors.password?.message
                    : "This field is required"}
                </span>
              )}
            </div>
            <div className="col-md-6 mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="Confirm Password"
                {...register("cPassword", {
                  required: true,
                  //   pattern: {
                  //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                  //     message: "Password should have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
                  //   },
                  validate: (val) => {
                    if (watch("password") != val) {
                      return "Your passwords do not match";
                    }
                  },
                })}
                aria-invalid={errors.cPassword ? "true" : "false"}
              />
              {errors.cPassword && (
                <span className="error">
                  {errors.cPassword?.message != ""
                    ? errors.cPassword?.message
                    : "This field is required"}
                </span>
              )}
            </div>
          </div>
          <div className="btns">
            <button className="btn btn-submit" disabled={loading}>
              Register {
                loading
                ? <i className="spinner-border spinnerStyle" style={{color: "black"}}></i>
                :<i className="bi bi-arrow-right"></i>
              }
            </button>
          </div>
        </form>
        <div className="register">
          <p className="reg-btn">
            Do you have an account ?{" "}
            <b onClick={() => loginHandler()} className="pointer">Login</b>
          </p>
          <p
            className="mb-0"
            dangerouslySetInnerHTML={{
              __html: data?.login_footer_text,
            }}
          ></p>
           {/* <b onClick={()=>termsNavigateHandler()} className="pointer">Terms and Conditions</b> */}
        </div>
      </div>
    </div>
  );
}

export default RegisterForm;
