import React, { useState } from 'react'
function AddAgent() {
    const [singleMulti, setSingleMulti] = useState('');
    const [option, setOption] = useState('');
    const [count, setCount] = useState('');

    const handleType = (e) => {
        setSingleMulti(e)
    }
    const handleOption = (e) => {
        setOption(e)
    }

    return (
        <section className="prima__contact">
            <div className="container">
                <div className="inner__banner fadeInUp delay1">
                    <h1>Add Owner Details</h1>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="mb-3">
                                    <label htmlFor="">Owner Type</label>
                                    <select className="form-control" id="exampleFormControlSelect1" onChange={(e) => handleType(e.target.value)}>
                                        <option>select</option>
                                        <option>Individual</option>
                                        <option value='company'>Company</option>
                                    </select>
                                </div>
                            </div>
                            {
                                singleMulti &&
                                <div className="col-md-4">
                                    <div className="mb-3">
                                        <label htmlFor="">Single Or Multiple Owner</label>
                                        <select className="form-control" id="exampleFormControlSelect1" onChange={(e) => handleOption(e.target.value)}>
                                            <option>select</option>
                                            <option value='single'>Single</option>
                                            <option value='multiple'>Multiple</option>
                                        </select>
                                    </div>
                                </div>
                            }
                            {
                                option == 'multiple' &&
                                <div className="col-md-4"><div className="mb-3">
                                    <label htmlFor="">Number Of Multiple Owners*</label>
                                    <input type="number" className='form-control' onChange={(e) => setCount(e.target.value)} placeholder='Type here' />
                                </div>
                                </div>
                            }



                            {
                                option == 'single' &&
                                <div className="col-md-12 mt-4">
                                    {
                                        singleMulti == 'company' &&
                                        <div className='row'>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label htmlFor="">Company Name*</label>
                                                    <input type="text" className='form-control' placeholder='Type here' />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label htmlFor="">Trade License*</label>
                                                    <input type="text" className='form-control' placeholder='Type here' />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="mb-3">
                                                    <label htmlFor="">VAT Number*</label>
                                                    <input type="text" className='form-control' placeholder='Type here' />
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="mb-3">
                                                <label htmlFor="">Owner Name*</label>
                                                <input type="text" className='form-control' placeholder='Type here' />
                                            </div>
                                        </div>
                                        <div className="col-md-4"><div className="mb-3">
                                            <label htmlFor="">Email*</label>
                                            <input type="email" className='form-control' placeholder='Type here' />
                                        </div>
                                        </div>
                                        <div className="col-md-4"><div className="mb-3">
                                            <label htmlFor="">Phone Number*</label>
                                            <input type="text" className='form-control' placeholder='Type here' />
                                        </div>
                                        </div>
                                        <div className="col-md-4"><div className="mb-3">
                                            <label htmlFor="">Alternate Phone Number*</label>
                                            <input type="text" className='form-control' placeholder='Type here' />
                                        </div>
                                        </div>
                                        <div className="col-md-4"><div className="mb-3">
                                            <label htmlFor="">Nationality*</label>
                                            <input type="text" className='form-control' placeholder='Type here' />
                                        </div>
                                        </div>
                                        <div className="col-md-4"><div className="mb-3">
                                            <label htmlFor="">Date Of Birth*</label>
                                            <input type="date" className='form-control' placeholder='Type here' />
                                        </div>
                                        </div>
                                        <div className="col-md-4"><div className="mb-3">
                                            <label htmlFor="">Passport ID Number*</label>
                                            <input type="text" className='form-control' placeholder='Type here' />
                                        </div>
                                        </div>
                                        <div className="col-md-4"><div className="mb-3">
                                            <label htmlFor="">Passport ID Expiry*</label>
                                            <input type="date" className='form-control' placeholder='Type here' />
                                        </div>
                                        </div>
                                        <div className="col-md-4"><div className="mb-3">
                                            <label htmlFor="">Passport ID Proof*</label>
                                            <input type="file" className='form-control' placeholder='Type here' />
                                        </div>
                                        </div>
                                        <div className="col-md-4"><div className="mb-3">
                                            <label htmlFor="">Type here ID Number*</label>
                                            <input type="text" className='form-control' placeholder='Type here' />
                                        </div>
                                        </div>

                                        <div className="col-md-4"><div className="mb-3">
                                            <label htmlFor="">Type here ID Expiry*</label>
                                            <input type="date" className='form-control' placeholder='Type here' />
                                        </div>
                                        </div>
                                        <div className="col-md-4"><div className="mb-3">
                                            <label htmlFor="">Type here ID Proof*</label>
                                            <input type="file" className='form-control' placeholder='Type here' />
                                        </div>
                                        </div>
                                        <div className="col-md-4"><div className="mb-3">
                                            <label htmlFor="">Visa Expiry*</label>
                                            <input type="date" className='form-control' placeholder='Type here' />
                                        </div>
                                        </div>
                                        <div className="col-md-4"><div className="mb-3">
                                            <label htmlFor="">Visa Proof*</label>
                                            <input type="file" className='form-control' placeholder='Type here' />
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                singleMulti == 'company' &&
                                <div className='row'>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="">Company Name*</label>
                                            <input type="text" className='form-control' placeholder='Type here' />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="">Trade License*</label>
                                            <input type="text" className='form-control' placeholder='Type here' />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="mb-3">
                                            <label htmlFor="">VAT Number*</label>
                                            <input type="text" className='form-control' placeholder='Type here' />
                                        </div>
                                    </div>
                                </div>
                            }

                            {
                                <div>
                                    {Array?.from({ length: count }, (_, index) => (
                                        <div className="col-md-12 mt-4" key={index}>
                                            <div className="col-md-12">
                                                <div className="details__head">
                                                    <h3>Owner Details {index + 1}</h3>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-md-4"><div className="mb-3">
                                                    <label htmlFor="">Owner Name*</label>
                                                    <input type="text" className='form-control' placeholder='Type here' />
                                                </div>
                                                </div>
                                                <div className="col-md-4"><div className="mb-3">
                                                    <label htmlFor="">Email*</label>
                                                    <input type="email" className='form-control' placeholder='Type here' />
                                                </div>
                                                </div>
                                                <div className="col-md-4"><div className="mb-3">
                                                    <label htmlFor="">Phone Number*</label>
                                                    <input type="text" className='form-control' placeholder='Type here' />
                                                </div>
                                                </div>
                                                <div className="col-md-4"><div className="mb-3">
                                                    <label htmlFor="">Alternate Phone Number*</label>
                                                    <input type="text" className='form-control' placeholder='Type here' />
                                                </div>
                                                </div>
                                                <div className="col-md-4"><div className="mb-3">
                                                    <label htmlFor="">Nationality*</label>
                                                    <input type="text" className='form-control' placeholder='Type here' />
                                                </div>
                                                </div>
                                                <div className="col-md-4"><div className="mb-3">
                                                    <label htmlFor="">Date Of Birth*</label>
                                                    <input type="date" className='form-control' placeholder='Type here' />
                                                </div>
                                                </div>
                                                <div className="col-md-4"><div className="mb-3">
                                                    <label htmlFor="">Passport ID Number*</label>
                                                    <input type="text" className='form-control' placeholder='Type here' />
                                                </div>
                                                </div>
                                                <div className="col-md-4"><div className="mb-3">
                                                    <label htmlFor="">Passport ID Expiry*</label>
                                                    <input type="date" className='form-control' placeholder='Type here' />
                                                </div>
                                                </div>
                                                <div className="col-md-4"><div className="mb-3">
                                                    <label htmlFor="">Passport ID Proof*</label>
                                                    <input type="file" className='form-control' placeholder='Type here' />
                                                </div>
                                                </div>
                                                <div className="col-md-4"><div className="mb-3">
                                                    <label htmlFor="">Type here ID Number*</label>
                                                    <input type="text" className='form-control' placeholder='Type here' />
                                                </div>
                                                </div>

                                                <div className="col-md-4"><div className="mb-3">
                                                    <label htmlFor="">Type here ID Expiry*</label>
                                                    <input type="date" className='form-control' placeholder='Type here' />
                                                </div>
                                                </div>
                                                <div className="col-md-4"><div className="mb-3">
                                                    <label htmlFor="">Type here ID Proof*</label>
                                                    <input type="file" className='form-control' placeholder='Type here' />
                                                </div>
                                                </div>
                                                <div className="col-md-4"><div className="mb-3">
                                                    <label htmlFor="">Visa Expiry*</label>
                                                    <input type="date" className='form-control' placeholder='Type here' />
                                                </div>
                                                </div>
                                                <div className="col-md-4"><div className="mb-3">
                                                    <label htmlFor="">Visa Proof*</label>
                                                    <input type="file" className='form-control' placeholder='Type here' />
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            }

                            {/* <div className="col-md-12 mt-4">
                                <div className="row">
                                    <div className="col-md-4"><div className="mb-3">
                                        <label htmlFor="">Owner Name*</label>
                                        <input type="text" className='form-control' placeholder='Type here' />
                                    </div>
                                    </div>
                                    <div className="col-md-4"><div className="mb-3">
                                        <label htmlFor="">Email*</label>
                                        <input type="email" className='form-control' placeholder='Type here' />
                                    </div>
                                    </div>
                                    <div className="col-md-4"><div className="mb-3">
                                        <label htmlFor="">Phone Number*</label>
                                        <input type="text" className='form-control' placeholder='Type here' />
                                    </div>
                                    </div>
                                    <div className="col-md-4"><div className="mb-3">
                                        <label htmlFor="">Alternate Phone Number*</label>
                                        <input type="text" className='form-control' placeholder='Type here' />
                                    </div>
                                    </div>
                                    <div className="col-md-4"><div className="mb-3">
                                        <label htmlFor="">Nationality*</label>
                                        <input type="text" className='form-control' placeholder='Type here' />
                                    </div>
                                    </div>
                                    <div className="col-md-4"><div className="mb-3">
                                        <label htmlFor="">Date Of Birth*</label>
                                        <input type="date" className='form-control' placeholder='Type here' />
                                    </div>
                                    </div>
                                    <div className="col-md-4"><div className="mb-3">
                                        <label htmlFor="">Passport ID Number*</label>
                                        <input type="text" className='form-control' placeholder='Type here' />
                                    </div>
                                    </div>
                                    <div className="col-md-4"><div className="mb-3">
                                        <label htmlFor="">Passport ID Expiry*</label>
                                        <input type="date" className='form-control' placeholder='Type here' />
                                    </div>
                                    </div>
                                    <div className="col-md-4"><div className="mb-3">
                                        <label htmlFor="">Passport ID Proof*</label>
                                        <input type="file" className='form-control' placeholder='Type here' />
                                    </div>
                                    </div>
                                    <div className="col-md-4"><div className="mb-3">
                                        <label htmlFor="">Type here ID Number*</label>
                                        <input type="text" className='form-control' placeholder='Type here' />
                                    </div>
                                    </div>

                                    <div className="col-md-4"><div className="mb-3">
                                        <label htmlFor="">Type here ID Expiry*</label>
                                        <input type="date" className='form-control' placeholder='Type here' />
                                    </div>
                                    </div>
                                    <div className="col-md-4"><div className="mb-3">
                                        <label htmlFor="">Type here ID Proof*</label>
                                        <input type="file" className='form-control' placeholder='Type here' />
                                    </div>
                                    </div>
                                    <div className="col-md-4"><div className="mb-3">
                                        <label htmlFor="">Visa Expiry*</label>
                                        <input type="date" className='form-control' placeholder='Type here' />
                                    </div>
                                    </div>
                                    <div className="col-md-4"><div className="mb-3">
                                        <label htmlFor="">Visa Proof*</label>
                                        <input type="file" className='form-control' placeholder='Type here' />
                                    </div>
                                    </div>
                                </div>
                            </div> */}


                            <div className="col-md-12">
                                <button className="btn btn-submit">SUBMIT NOW</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </section>
    )
}

export default AddAgent
