import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCms } from '../redux/actionCreator'
import { about_details, about_images, general_path } from '../redux/commonReducer'
import { useEffect } from 'react'
import { webURL } from '../constants/configuration'
import logo from "../assets/image/logo.svg";
import MetaHead from './metaHead'
function About() {
  const dispatch = useDispatch()

  const aboutDetails = useSelector(about_details)
  const generalPath = useSelector(general_path)
  const aboutImages = useSelector(about_images)
  const images = aboutDetails?.image

  useEffect(() => {
    dispatch(getCms())
  }, [])

  console.log('123', aboutImages)
  return (
    <section className='prima__about' >
      <MetaHead title={aboutDetails?.meta_title} keyword={aboutDetails?.meta_keyword} description={aboutDetails?.meta_description}/>
      <div className="container">
        <div className="inner__banner">
          <h1><i>About</i> PRIMA</h1>
          <h3>{aboutDetails?.sub_title}</h3>
        </div>
      </div>
      <div className="container-fluid p-0">
        <div className="banner__images">
          {
            aboutImages?.map((ele, i) => {
              if (!ele=='') {
                return <div className="banner__img" key={i}>
                  <img src={webURL + generalPath + "/" + ele?.gallery_image} alt="" />
                </div>
              } else {
                return <div className="banner__img dummy" key={i}>
                  <img src={logo} alt="" />
                </div>
              }
            })
          }
        </div>
      </div>
      <div className="container">
        <div className="about__description" dangerouslySetInnerHTML={{ __html: aboutDetails?.description, }} />
      </div>
      <div className="prima__vision_mission">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="vision_mission_grid vision">
                <div className="icon">
                  <img src={webURL + generalPath + "/" + aboutDetails?.vision_icon} alt="" />
                  <h3>{aboutDetails?.vision_title}</h3>
                </div>
                <p dangerouslySetInnerHTML={{ __html: aboutDetails?.vision_description, }} />
              </div>
            </div>
            <div className="col-md-5">
              <div className="vision_mission_grid">
                <div className="icon">
                  <img src={webURL + generalPath + "/" + aboutDetails?.mission_icon} alt="" />
                  <h3>{aboutDetails?.mission_title}</h3>
                </div>
                <p dangerouslySetInnerHTML={{ __html: aboutDetails?.mission_description, }} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="chairman__message" id='chairman'>
        <div className="container-fluid">
          <h2>{aboutDetails?.chairman_message_title}</h2>
          <div className="row">
            <div className="col-md-5 pl-0">
              <div className="chairman__img">
                <img src={webURL + generalPath + "/" + aboutDetails?.chairman_message_image} className='w-100' alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="chairman__quotes">
                <p dangerouslySetInnerHTML={{ __html: aboutDetails?.chairman_message_description, }}></p>
                <div className="name">
                  <h5>{aboutDetails?.chairman_message_name},</h5>
                  <h6>{aboutDetails?.chairman_message_designation}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
