import Rectangle82 from '../assets/image/Rectangle 82.png'
import Rectangle85 from '../assets/image/Rectangle 85.png'
import Rectangle86 from '../assets/image/Rectangle 86.png'
import Rectangle89 from '../assets/image/Rectangle 89.png'
import Rectangle90 from '../assets/image/Rectangle 90.png'
import Rectangle91 from '../assets/image/Rectangle 91.png'
import Rectangle93 from '../assets/image/Rectangle 93.png'
import Rectangle95 from '../assets/image/Rectangle 95.png'
import Rectangle97 from '../assets/image/Rectangle 97.png'
import Rectangle99 from '../assets/image/Rectangle 99.png'
import Rectangle101 from '../assets/image/Rectangle 101.png'
import Rectangle100 from '../assets/image/Rectangle 100.png'
import MainSection from '../components/MainSection';
import AboutSection from '../components/AboutSection';
import FeaturedSection from '../components/FeaturedSection';
import PremiumPropertiesSection from '../components/PremiumPropertiesSection';
import GuideSection from '../components/GuideSection';
import LocationSection from '../components/LocationSection';
import FooterSection from '../components/FooterSection';
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getHome } from '../redux/actionCreator'
import { home_details, home_loading, home_premium, updateRedux } from '../redux/commonReducer'
import PreLoader from '../components/PreLoader'
import MetaHead from './metaHead'

function Home() {
  const dispatch = useDispatch()

  const homeDetails = useSelector(home_details)
  const homePremium = useSelector(home_premium)
  const loading = useSelector(home_loading)

  useEffect(() => {
    dispatch(updateRedux({ key: 'home_loading', value: true }))
    dispatch(getHome())
  }, [])

  return (
    <div className="App">
      {
        !loading ?
          <>
          <MetaHead title={homeDetails?.meta_title} keyword={homeDetails?.meta_keyword} description={homeDetails?.meta_description}/> 
            <MainSection details={homeDetails} />
            <AboutSection details={homeDetails} />
            <FeaturedSection details={homeDetails} />
            {
              homeDetails?.premium_property?.length != 0
              ? <PremiumPropertiesSection premiumProperties={homePremium} />
              : null
            }
            {
              homeDetails?.guide?.length != 0
              ?<GuideSection details={homeDetails?.guide} />
              : null
            }
            <LocationSection />
            <FooterSection/>
          </> :
          <PreLoader />
      }


    </div >
  );
}

export default Home;
