import React from 'react'
import { Spinner } from 'react-bootstrap'

const LoadingSpinner = ({height}) => {
    return (
        <div style={{width:'100%', height: height ? height: 200,display: 'flex',flexDirection: 'column', alignItems:'center', justifyContent:'center'}}>
            <Spinner animation="grow" size="xl" />
        </div>
    )
}

export default LoadingSpinner