import React from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

import "swiper/css";
import "swiper/css/navigation";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  addWishList,
  deleteWishList,
  getSimilarProperties,
} from "../redux/actionCreator";
import { similar_properties, thumbnail_path, updateRedux, user_logged } from "../redux/commonReducer";
import { NumericFormat } from "react-number-format";
import { webURL } from "../constants/configuration";
import { toast } from "react-toastify";

function SimilarProperties({ id }) {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const similarProperties = useSelector(similar_properties);
  const thumbnailPath = useSelector(thumbnail_path);
  const userLogged = useSelector(user_logged)

  useEffect(() => {
    console.log(id);
    dispatch(getSimilarProperties(id));
  }, [id, userLogged]);

  const removeWishlistHandler = (fav_id) => {
    if (token != null) {
      let data = {
        property_id: fav_id,
      };
      dispatch(
        deleteWishList(data, () => {
          dispatch(getSimilarProperties(id));
        })
      );
    } else {
      dispatch(updateRedux({ key: 'login_modal_show', value: true }))
      // toast.error("Please Login", {
      //   position: "bottom-center",
      //   autoClose: 3000,
      // });
    }
  };

  const addWishlistHandler = (fav_id) => {
    if (token != null) {
      let data = {
        property_id: fav_id,
      };
      dispatch(
        addWishList(data, () => {
          dispatch(getSimilarProperties(id));
        })
      );
    } else {
      dispatch(updateRedux({ key: 'login_modal_show', value: true }))
      // toast.error("Please Login", {
      //   position: "bottom-center",
      //   autoClose: 3000,
      // });
    }
  };

  return (
    <section className="similar__properties">
      {similarProperties?.length != 0 ? (
        <div className="container">
          <h2>SIMILAR PROPERTIES</h2>
          <Swiper
            slidesPerView={1}
            spaceBetween={0}
            // loop={true}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            navigation={true}
            modules={[Navigation]}
            className="mySwiper"
          >
            {similarProperties?.map((ele, i) => {
              return (
                <SwiperSlide>
                  <div className="property__lists">
                    <div className="property__img">
                      <Link to={`${"/property-details/" + ele?.slug}`}>
                        <img
                          src={
                            webURL +
                            thumbnailPath +
                            "/" +
                            ele?.property_thumbnail_image
                          }
                          alt=""
                        />
                      </Link>
                      <div className="property__tag">
                        {/* <div className="tag"><p>{ele?.buy} <span>|</span> Ready</p></div> */}
                        <div className="tag">
                          <p>
                            {ele?.buy == "rent" ? (
                              "For rent"
                            ) : (
                              <>
                                For Sale <span>|</span> Ready
                              </>
                            )}{" "}
                          </p>
                        </div>
                        <div className="whishlist">
                          {ele?.is_favourite ? (
                            <i
                              className="bi bi-heart-fill pointer"
                              onClick={() => removeWishlistHandler(ele._id)}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-heart pointer"
                              onClick={() => addWishlistHandler(ele._id)}
                            ></i>
                          )}
                        </div>
                      </div>
                    </div>
                    <Link to={`${"/property-details/" + ele?.slug}`}>
                      <div className="property__title">
                        <h2>{ele?.property_name}</h2>
                        <div className="property__price">
                          {ele?.askprice == "1" ? (
                            <h3 className="ask__price">ask for price</h3>
                          ) : (
                            <h3>
                              <NumericFormat
                                value={ele?.price}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"AED "}
                              />
                            </h3>
                          )}

                          <h6>{ele?.residential}</h6>
                        </div>
                        <div className="property__features">
                          <ul>
                            <li>
                              {ele?.residential == "commercial" ? (
                                <>{ele?.pantry} Pantry</>
                              ) : ele?.bedroom == "0" ||
                                ele?.bedroom == "Studio" ? (
                                "Studio"
                              ) : (
                                <>{ele?.bedroom} Bedroom</>
                              )}{" "}
                            </li>
                            <li>{ele?.bathroom} Bathroom</li>
                            <li className="lowercase">{ele?.sqft} sq.ft.</li>
                          </ul>
                        </div>
                      </div>
                    </Link>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      ) : (
        ""
      )}
    </section>
  );
}

export default SimilarProperties;
