import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { allSearchResults } from '../redux/actionCreator'
import { guide_path, loading_spinner, searchItems_guide, searchItems_rent, searchItems_sale, thumbnail_path } from '../redux/commonReducer'
import { webURL } from '../constants/configuration'
import { Link } from 'react-router-dom'
import LoadingSpinner from '../components/LoadingSpinner'

function SearchResults() {
    const location = useLocation()
    const dispatch = useDispatch()
    const saleLists = useSelector(searchItems_sale)
    const rentLists = useSelector(searchItems_rent)
    const guideLists = useSelector(searchItems_guide)
    const thumbnailPath = useSelector(thumbnail_path);
    const guidePath = useSelector(guide_path)
    const loading = useSelector(loading_spinner)

    useEffect(() => {
        let data = {
            keyword: location?.state?.key,
        };
        dispatch(allSearchResults(data))
    }, [location])


    console.log('location', location)

    return (
        <section className='search__results'>
            <div className="inner__banner fadeInUp delay1">
                <h1>SEARCH RESULT : “{location?.state?.key}”</h1>
            </div>
            <div className="container">

                {
                    !loading ?
                        saleLists?.length == 0 && rentLists?.length == 0 && guideLists?.length == 0 ?
                            <div className='no__data'>
                                <h2>No Data Found</h2>
                            </div>
                            :
                            <>
                                {
                                    saleLists?.length > 0 ?
                                        <>
                                            <div className="result__title">
                                                <h2>BUY</h2>
                                            </div>
                                            {
                                                saleLists?.map((ele, i) => {
                                                    return (
                                                        <div className="search__grids">
                                                            <div className="row align-items-start">
                                                                <div className="col-md-2">
                                                                    <div className="image__grid">
                                                                        <img src={
                                                                            webURL +
                                                                            thumbnailPath +
                                                                            "/" +
                                                                            ele?.property_thumbnail_image
                                                                        } className='w-100' alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <Link to={`${"/property-details/" + ele?.slug}`}>
                                                                        <div className="search__grid">
                                                                            <h2>{ele?.property_name}</h2>
                                                                            <ul>
                                                                                <li>
                                                                                    {ele?.residential == "commercial" ? (
                                                                                        <>{ele?.pantry} Pantry</>
                                                                                    ) : ele?.bedroom == "0" ||
                                                                                        ele?.bedroom == "Studio" ? (
                                                                                        "Studio"
                                                                                    ) : (
                                                                                        <> {ele?.bedroom} Bedroom</>
                                                                                    )}{" "}
                                                                                </li>
                                                                                <li>{ele?.bathroom} Bathroom</li>
                                                                                <li>{ele?.sqft} sq.ft.</li>
                                                                            </ul>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </> : ""
                                }




                                {
                                    rentLists?.length > 0 ?
                                        <><hr />
                                            <div className="result__title">
                                                <h2>RENT</h2>
                                            </div>
                                            {
                                                rentLists?.map((ele, i) => {
                                                    return (
                                                        <div className="search__grids">
                                                            <div className="row align-items-start">
                                                                <div className="col-md-2">
                                                                    <div className="image__grid">
                                                                        <img src={
                                                                            webURL +
                                                                            thumbnailPath +
                                                                            "/" +
                                                                            ele?.property_thumbnail_image
                                                                        } className='w-100' alt="" />
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-10">
                                                                    <Link to={`${"/property-details/" + ele?.slug}`}>
                                                                        <div className="search__grid">
                                                                            <h2>{ele?.property_name}</h2>
                                                                            <ul>
                                                                                <li>
                                                                                    {ele?.residential == "commercial" ? (
                                                                                        <>{ele?.pantry} Pantry</>
                                                                                    ) : ele?.bedroom == "0" ||
                                                                                        ele?.bedroom == "Studio" ? (
                                                                                        "Studio"
                                                                                    ) : (
                                                                                        <> {ele?.bedroom} Bedroom</>
                                                                                    )}{" "}
                                                                                </li>
                                                                                <li>{ele?.bathroom} Bathroom</li>
                                                                                <li>{ele?.sqft} sq.ft.</li>
                                                                            </ul>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                            <hr />

                                        </> : ""
                                }
                                {
                                    guideLists?.length > 0 ?
                                        <>
                                            <div className="result__title">
                                                <h2>GUIDE</h2>
                                            </div>
                                            {
                                                guideLists?.map((ele, i) => {
                                                    return (
                                                        <div className="search__grids">
                                                            <Link
                                                                to={`${"/guide_details/" + ele?.slug}`}
                                                                className="underline-0"
                                                            >
                                                                <div className="row align-items-start">
                                                                    <div className="col-md-2">
                                                                        <div className="image__grid">
                                                                            <img src={webURL + guidePath + "/" + ele?.guide_image} className='w-100' alt="" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-10">
                                                                        <Link to={`${'/guide_details/' + ele?.slug}`}>
                                                                            <div className="search__grid">
                                                                                <h2>{ele?.guide_title}</h2>
                                                                                <p dangerouslySetInnerHTML={{ __html: ele?.short_description }}></p>
                                                                            </div>
                                                                        </Link>
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </> : ""
                                }
                            </>
                        : <LoadingSpinner />
                }

            </div>
        </section>
    )
}

export default SearchResults
