import React, { useEffect } from 'react'
import Header from '../components/Header'
import { Container } from 'react-bootstrap'
import './PrivacyPolicy.css'
import FooterSection from '../components/FooterSection'
import { useDispatch, useSelector } from 'react-redux'
import { getCms } from '../redux/actionCreator'
import { privacy_details, terms_details } from '../redux/commonReducer'

const PrivacyPolicy = ({ page }) => {
  const dispatch = useDispatch()

  const privacyDetails = useSelector(privacy_details)
  const termsDetails = useSelector(terms_details)

  useEffect(() => {
    dispatch(getCms())
  }, [])
  return (
    <div className='section__terms'>
      {page === "terms" ?
        <Container className='privacy-content'>
          <h2 className='privacy-title' data-aos="fade-up">{termsDetails?.title}</h2>
          <div className='privacy-title-underline' data-aos="fade-up"></div>
          <div className='privacy-introduction' data-aos="fade-up" dangerouslySetInnerHTML={{ __html: termsDetails?.description, }} />
        </Container> :
        <Container className='privacy-content'>
          <h2 className='privacy-title' data-aos="fade-up">{privacyDetails?.title}</h2>
          <div className='privacy-title-underline' data-aos="fade-up"></div>
          <div className='privacy-introduction' data-aos="fade-up" dangerouslySetInnerHTML={{ __html: privacyDetails?.description, }} />
        </Container>}
    </div>
  )
}

export default PrivacyPolicy