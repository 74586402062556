import React from "react";
import { useNavigate } from "react-router-dom";
import { getHome, login, registerNow } from "../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { general_details, login_loading, updateRedux } from "../redux/commonReducer";
import { useForm } from "react-hook-form";

function Login({ show, setShow }) {

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
  } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate()

  const data = useSelector(general_details);
  const loading = useSelector(login_loading)

  const onSubmit = (data) => {
    console.log(data, "data");
    let value = {
      email: data?.email,
      password: data?.password,
    };
    dispatch(
      login(value, () => {
        dispatch(getHome())
        setShow(false);
        dispatch(updateRedux({ key: 'login_modal_show', value: false }))
      })
    );
  };

  const forgotPasswordHandler = () => {
    dispatch(updateRedux({ key: 'register_state', value: false }))
    dispatch(updateRedux({ key: 'forgot_password', value: true }))
  }

  const termsNavigateHandler = () => {
    setShow(false);
    dispatch(updateRedux({ key: 'login_modal_show', value: false }))
    dispatch(updateRedux({ key: 'register_state', value: false }))
    dispatch(updateRedux({ key: 'forgot_password', value: false }))
    navigate("/terms_and_conditions")
  }

  return (
    <div className="prima__login fadeInUp delay1">
      <div className="inner__banner">
        <h1>{data?.login_title}</h1>
        <h6
          dangerouslySetInnerHTML={{
            __html: data?.login_tagline,
          }}
        ></h6>
      </div>
      <div className="forms">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="col-md-12 mb-3">
          <input
            type="text"
            autoComplete="on"
            className="form-control"
            placeholder="Email Address"
            {...register("email", {
              required: true,
              pattern: {
                value:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
                message: "Entered value does not match email format",
              },
            })}
            aria-invalid={errors.email ? "true" : "false"}
          />
          {errors.email && (
            <span className="error">
              {errors.email?.message != ""
                ? errors.email?.message
                : "This field is required"}
            </span>
          )}
          </div>
          <div className="col-md-12 mb-3">
          <input
            type="password"
            className="form-control"
            placeholder="Password"
            {...register("password", {
              required: true,
              pattern: {
                value:
                  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&,._])[A-Za-z\d#@$!%*?&,._]{8,}$/,
                message:
                  "Password should have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
              },
            })}
            aria-invalid={errors.password ? "true" : "false"}
          />
          {errors.password && (
            <span className="error">
              {errors.password?.message != ""
                ? errors.password?.message
                : "This field is required"}
            </span>
          )}
          </div>
         
         
          <div className="btns">
            <button className="btn btn-submit" disabled={loading}>
              Submit {
                loading
                ? <i className="spinner-border spinnerStyle" style={{color: "black"}}></i>
                :<i className="bi bi-arrow-right"></i>
              }
            </button>
            <span onClick={()=>forgotPasswordHandler()} className="pointer">Forgot Password?</span>
          </div>
        </form>
        <div className="register">
          <p className="reg-btn">
            Don’t have an account ?{" "}
            <b onClick={() => dispatch(registerNow(true))} className="pointer">Register Now</b>
          </p>
          <p
            className="mb-0"
            dangerouslySetInnerHTML={{
              __html: data?.login_footer_text,
            }}
          ></p>
          {/* <b onClick={()=>termsNavigateHandler()} className="pointer">Terms and Conditions</b> */}
        </div>
      </div>
    </div>
  );
}

export default Login;
