import React from 'react'

function NoData() {
  return (
    <div className='container'>
      <div className="no__data">
        <h2>No Data Found</h2>
      </div>
    </div>
  )
}

export default NoData
