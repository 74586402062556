import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  forgotPassword,
  resetPassword,
  verifyPasswordOtp,
} from "../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import {
  forgot_loading,
  general_details,
  otp_state,
  otp_verified,
  reset_loading,
  updateRedux,
  user_email,
} from "../redux/commonReducer";
import { useForm } from "react-hook-form";

function ForgotPassword({ show, setShow }) {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector(general_details);
  const otpState = useSelector(otp_state);
  const otpVerified = useSelector(otp_verified);
  const userEmail = useSelector(user_email);
  const loading = useSelector(forgot_loading);
  const resetLoading = useSelector(reset_loading);

  useEffect(() => {
    dispatch(updateRedux({ key: "otp_state", value: false }));
    dispatch(updateRedux({ key: "otp_verified", value: false }));
  }, []);

  const onSubmit = (data) => {
    dispatch(updateRedux({ key: "user_email", value: data?.email }));
    let value = {
      email: data?.email,
    };
    dispatch(
      forgotPassword(value, () => {
        // setShow(false);
        reset();
        // dispatch(updateRedux({ key: 'register_state', value: false }))
        // dispatch(updateRedux({ key: 'forgot_password', value: false }))
      })
    );
  };

  const otpVerify = (data) => {
    console.log(data);
    let value = {
      email: userEmail,
      otp: data?.otp,
    };
    dispatch(
      verifyPasswordOtp(value, () => {
        console.log("14785666");
        // setShow(false);
        reset();
        // dispatch(updateRedux({ key: 'register_state', value: false }))
        // dispatch(updateRedux({ key: 'forgot_password', value: false }))
      })
    );
  };

  const createNewPass = (data) => {
    let value = {
      email: userEmail,
      password: data?.password,
    };
    dispatch(
      resetPassword(value, () => {
        console.log("1233");
        setShow(false);
        dispatch(updateRedux({ key: "login_modal_show", value: false }));
        reset();
        dispatch(updateRedux({ key: "register_state", value: false }));
        dispatch(updateRedux({ key: "forgot_password", value: false }));
        dispatch(updateRedux({ key: "otp_state", value: false }));
        dispatch(updateRedux({ key: "otp_verified", value: false }));
      })
    );
  };

  const loginHandler = () => {
    dispatch(updateRedux({ key: "register_state", value: false }));
    dispatch(updateRedux({ key: "forgot_password", value: false }));
  };

  const termsNavigateHandler = () => {
    setShow(false);
    dispatch(updateRedux({ key: "login_modal_show", value: false }));
    dispatch(updateRedux({ key: "register_state", value: false }));
    dispatch(updateRedux({ key: "forgot_password", value: false }));
    navigate("/terms_and_conditions");
  };

  const onCancelHander = () => {
    reset();
    dispatch(updateRedux({ key: "register_state", value: false }));
    dispatch(updateRedux({ key: "forgot_password", value: false }));
  };
  console.log("otpState", otpState);

  return (
    <div className="prima__login fadeInUp delay1">
      <div className="inner__banner">
        <h1>{data?.forget_title}</h1>
        <h6
          dangerouslySetInnerHTML={{
            __html: data?.forget_tagline,
          }}
        ></h6>
      </div>
      {!otpVerified ? (
        <>
          {!otpState ? (
            <div className="forms">
              <form onSubmit={handleSubmit(onSubmit)}>
                <input
                  type="text"
                  autoComplete="off"
                  className="form-control"
                  placeholder="Email Address"
                  {...register("email", {
                    required: true,
                    pattern: {
                      value:
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
                      message: "Entered value does not match email format",
                    },
                  })}
                  aria-invalid={errors.email ? "true" : "false"}
                />
                {errors.email && (
                  <span className="error">
                    {errors.email?.message != ""
                      ? errors.email?.message
                      : "This field is required"}
                  </span>
                )}

                <div className="btns">
                  <button className="btn btn-submit" disabled={loading}>
                    Submit{" "}
                    {loading ? (
                      <i className="spinner-border spinnerStyle" style={{color: "black"}}></i>
                    ) : (
                      <i className="bi bi-arrow-right"></i>
                    )}
                  </button>
                  {/* <button className="btn" onClick={()=>onCancelHander()}>
              Cancel
            </button> */}
                </div>
              </form>
              <div className="register">
                <p className="reg-btn">
                  Do you have an account ?{" "}
                  <b onClick={() => loginHandler()} className="pointer">
                    Login
                  </b>
                </p>
                <p
                  className="mb-0"
                  dangerouslySetInnerHTML={{
                    __html: data?.login_footer_text,
                  }}
                ></p>
                {/* <b onClick={() => termsNavigateHandler()} className="pointer">Terms and Conditions</b> */}
              </div>
            </div>
          ) : (
            <div className="forms">
              <form onSubmit={handleSubmit(otpVerify)}>
                <input
                  type="text"
                  name="verify"
                  autoComplete="off"
                  className="form-control"
                  placeholder="Enter your OTP"
                  {...register("otp", {
                    required: true,
                  })}
                  aria-invalid={errors.otp ? "true" : "false"}
                />
                {errors.email && (
                  <span className="error">
                    {errors.email?.message != ""
                      ? errors.email?.message
                      : "This field is required"}
                  </span>
                )}

                <div className="btns">
                  <button className="btn btn-submit">
                    Verify <i className="bi bi-arrow-right"></i>
                  </button>
                </div>
              </form>
              <div className="register">
                <p className="reg-btn">
                  Do you have an account ?{" "}
                  <b onClick={() => loginHandler()} className="pointer">
                    Login
                  </b>
                </p>
                <p
                  className="mb-0"
                  dangerouslySetInnerHTML={{
                    __html: data?.login_footer_text,
                  }}
                ></p>
                {/* <b onClick={() => termsNavigateHandler()} className="pointer">Terms and Conditions</b> */}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="forms">
          <form onSubmit={handleSubmit(createNewPass)}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  autoComplete="off"
                  {...register("password", {
                    required: true,
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@$!%*?&,._])[A-Za-z\d#@$!%*?&,._]{8,}$/,
                      message:
                        "Password should have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character (Accepted Characters are #@$!%*?&,_.)",
                    },
                  })}
                  aria-invalid={errors.password ? "true" : "false"}
                />
                {errors.password && (
                  <span className="error">
                    {errors.password?.message != ""
                      ? errors.password?.message
                      : "This field is required"}
                  </span>
                )}
              </div>
              <div className="col-md-6 mb-3">
                <input
                  type="password"
                  className="form-control"
                  autoComplete="off"
                  placeholder="Confirm Password"
                  {...register("cPassword", {
                    required: true,
                    //   pattern: {
                    //     value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    //     message: "Password should have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character",
                    //   },
                    validate: (val) => {
                      if (watch("password") != val) {
                        return "Your passwords do not match";
                      }
                    },
                  })}
                  aria-invalid={errors.cPassword ? "true" : "false"}
                />
                {errors.cPassword && (
                  <span className="error">
                    {errors.cPassword?.message != ""
                      ? errors.cPassword?.message
                      : "This field is required"}
                  </span>
                )}
              </div>
            </div>

            <div className="btns">
              <button className="btn btn-submit" disabled={resetLoading}>
                Submit{" "}
                {resetLoading ? (
                  <i className="spinner-border spinnerStyle" style={{color: "black"}}></i>
                ) : (
                  <i className="bi bi-arrow-right"></i>
                )}
              </button>
            </div>
          </form>
          <div className="register">
            <p className="reg-btn">
              Do you have an account ?{" "}
              <b onClick={() => loginHandler()} className="pointer">
                Login
              </b>
            </p>
            <p
              className="mb-0"
              dangerouslySetInnerHTML={{
                __html: data?.login_footer_text,
              }}
            ></p>
            {/* <b onClick={() => termsNavigateHandler()} className="pointer">Terms and Conditions</b> */}
          </div>
        </div>
      )}
    </div>
  );
}

export default ForgotPassword;
