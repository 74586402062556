import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { careerEnquiry, getCareerDetails } from "../redux/actionCreator";
import { useEffect } from "react";
import { career_details, join_team_loading } from "../redux/commonReducer";
import { useState } from "react";
import { useForm } from "react-hook-form";
import MetaHead from "./metaHead";

function MarketingAssistance() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const { id } = useParams();
  const dispatch = useDispatch();
  const [resume, setResume] = useState("");
  const [resumeErr, setResumeErr] = useState("");
  const careerDetails = useSelector(career_details);
  const loading = useSelector(join_team_loading);

  useEffect(() => {
    dispatch(getCareerDetails(id));
  }, []);

  const onFileChangeHandler = (e) => {
    setResume("");
    const file = e.target.files[0];
    const fileType = file.name
      .substr(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    if (["pdf", "PDF", "docx", "doc"].includes(fileType)) {
      setResume(e.target.files[0]);
      setResumeErr("");
    } else {
      setResumeErr("Please upload file with pdf, docx, doc formats");
    }
  };

  const onSubmit = (data) => {
    console.log(data, "data");
    if (resume?.length == 0) {
      setResumeErr("Please upload resume");
    } else {
      let formData = new FormData();
      formData.append("first_name", data?.first_name);
      formData.append("last_name", data?.last_name);
      formData.append("email", data?.email);
      formData.append("phone", data?.phone);
      formData.append("cover_letter", data?.cover_letter);
      formData.append("position", careerDetails?._id);
      formData.append("resume", resume);
      console.log(formData);
      dispatch(
        careerEnquiry(formData, () => {
          reset();
          setResume("");
          setResumeErr("");
        })
      );
    }
  };

  return (
    <section className="marketing__assistance">
      <MetaHead
        title={careerDetails?.meta_title}
        keyword={careerDetails?.meta_keyword}
        description={careerDetails?.meta_description}
      />
      <div className="container">
        <div className="inner__banner fadeInUp delay1">
          <h1>{careerDetails?.position_name}</h1>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="description">
              <h4>ABOUT THE JOB</h4>
              <p
                dangerouslySetInnerHTML={{ __html: careerDetails?.about_job }}
              ></p>
              {careerDetails?.skills?.length != 0 && (
                <>
                  <hr />
                  <h4>RESPONSIBILITIES</h4>
                  <ul>
                    {careerDetails?.responsibilities?.map((ele) => {
                      return (
                        <li>
                          <i className="bi bi-check-circle-fill"></i> {ele}
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}

              {careerDetails?.skills?.length != 0 && (
                <>
                  <hr />
                  <h4>SKILLS AND QUALIFICATIONS</h4>
                  <ul>
                    {careerDetails?.skills?.map((ele) => {
                      return (
                        <li>
                          <i className="bi bi-check-circle-fill"></i> {ele}
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <div className="submit__form">
              <div className="inner__banner fadeInUp delay1">
                <h1>JOIN OUR TEAM</h1>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name*"
                      {...register("first_name", {
                        required: true,
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "First name should have only alphabets",
                        },
                      })}
                      aria-invalid={errors.first_name ? "true" : "false"}
                    />
                    {errors.first_name && (
                      <span className="error">
                        {errors.first_name?.message != ""
                          ? errors.first_name?.message
                          : "This field is required"}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name*"
                      {...register("last_name", {
                        required: true,
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Last name should have only alphabets",
                        },
                      })}
                      aria-invalid={errors.last_name ? "true" : "false"}
                    />
                    {errors.last_name && (
                      <span className="error">
                        {errors.last_name?.message != ""
                          ? errors.last_name?.message
                          : "This field is required"}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email*"
                      {...register("email", {
                        required: true,
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
                          message: "Entered value does not match email format",
                        },
                      })}
                      aria-invalid={errors.email ? "true" : "false"}
                    />
                    {errors.email && (
                      <span className="error">
                        {errors.email?.message != ""
                          ? errors.email?.message
                          : "This field is required"}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone*"
                      {...register("phone", {
                        required: true,
                        pattern: {
                          value: /^[0-9]{9,13}$/,
                          message:
                            "Invalid Phone Number (Phone field should have numbers only)",
                        },
                      })}
                      aria-invalid={errors.phone ? "true" : "false"}
                    />
                    {errors.phone && (
                      <span className="error">
                        {errors.phone?.message != ""
                          ? errors.phone?.message
                          : "This field is required"}
                      </span>
                    )}
                  </div>
                  <div className="col-md-12 mb-2">
                    <label htmlFor="">Cover Letter*</label>
                    <textarea
                      name=""
                      rows="6"
                      className="form-control"
                      {...register("cover_letter", {
                        required: true,
                      })}
                      aria-invalid={errors.cover_letter ? "true" : "false"}
                      placeholder="Please tell us more about yourself and elaborate on your motivation for becoming a part of our company"
                    ></textarea>
                    {errors.cover_letter && (
                      <p className="error">
                        {errors.cover_letter?.message != ""
                          ? errors.cover_letter?.message
                          : "Please tell us more about yourself and elaborate on your motivation for becoming a part of our company"}
                      </p>
                    )}
                  </div>
                  <div className="col-md-12 mb-2">
                    <div className="d-flex align-items-end">
                      <div className="add-file">
                        <input
                          type="file"
                          id="contact_file"
                          name="file"
                          aria-required="true"
                          aria-invalid="false"
                          onClick={(e) => (e.target.value = null)}
                          accept=".pdf, .PDF, .docx, .doc"
                          onChange={(e) => onFileChangeHandler(e)}
                        />
                        {/* <a href="" className="add-file-btn">Upload CV</a> */}
                        <span>{resume?.name ?? "Upload CV"}</span>
                      </div>
                      <button
                        className="btn btn-upload"
                        onClick={() =>
                          document?.getElementById("contact_file")?.click()
                        }
                      >
                        Upload <i className="bi bi-chevron-right"></i>
                      </button>
                    </div>
                    {resumeErr && <span className="error">{resumeErr}</span>}
                  </div>
                  <div className="col-md-12 mt-4">
                    <button className="btn-primary" disabled={loading}>
                      APPLY NOW{" "}
                      {loading ? (
                        <i className="spinner-border spinnerStyle" style={{color: "black"}}></i>
                      ) : (
                        <i className="bi bi-arrow-right"></i>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MarketingAssistance;
