import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  amenities_lists,
  amenities_lists_for_filter,
  property_types,
  updateRedux,
} from "../redux/commonReducer";
import Select from "react-select";
import {
  getFeatures,
  getFeaturesForFilter,
  getPropertyTypes,
  getPropertyTypesForSearch,
} from "../redux/actionCreator";
import { MultiSelect } from "react-multi-select-component";

function AdvancedSearchHome({
  applyFIlters,
  filterData,
  setFilterData,
  clearFIlters,
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const propertyTypeList = useSelector(property_types);
  const amenitiesList = useSelector(amenities_lists_for_filter);

  const [show, setShow] = useState(false);
  const [minErr, setMinErr] = useState("");
  const [maxErr, setMaxErr] = useState("");
  const [maxSqftErr, setMaxSqftErr] = useState("");
  const [minSqftErr, setMinSqftErr] = useState("");

  const minPriceList = [
    { value: 1000, label: "1000" },
    { value: 2000, label: "2000" },
    { value: 3000, label: "3000" },
    { value: 4000, label: "4000" },
    { value: 5000, label: "5000" },
    { value: 6000, label: "6000" },
    { value: 7000, label: "7000" },
    { value: 9000, label: "9000" },
  ];
  const maxPriceList = [
    { value: 1000, label: "1000" },
    { value: 2000, label: "2000" },
    { value: 3000, label: "3000" },
    { value: 4000, label: "4000" },
    { value: 5000, label: "5000" },
    { value: 6000, label: "6000" },
    { value: 7000, label: "7000" },
    { value: 9000, label: "9000" },
  ];
  const bedroomsList = [
    { value: "0", label: "Studio" },
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];
  const bathroomsList = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
    { value: "6", label: "6" },
    { value: "7", label: "7" },
    { value: "8", label: "8" },
    { value: "9", label: "9" },
    { value: "10", label: "10" },
  ];
  const minSqftList = [
    { value: 1000, label: "1000" },
    { value: 2000, label: "2000" },
    { value: 3000, label: "3000" },
    { value: 4000, label: "4000" },
    { value: 5000, label: "5000" },
    { value: 6000, label: "6000" },
    { value: 7000, label: "7000" },
    { value: 9000, label: "9000" },
  ];
  const maxSqftList = [
    { value: 1000, label: "1000" },
    { value: 2000, label: "2000" },
    { value: 3000, label: "3000" },
    { value: 4000, label: "4000" },
    { value: 5000, label: "5000" },
    { value: 6000, label: "6000" },
    { value: 7000, label: "7000" },
    { value: 9000, label: "9000" },
  ];

  useEffect(() => {
    dispatch(getPropertyTypesForSearch());
    dispatch(getFeatures());
    dispatch(getFeaturesForFilter());
  }, []);

  const advancedHandler = () => {
    dispatch(updateRedux({ key: "advanced_search", value: !show }));
    setShow(!show);
  };

  const handleClick = (e) => {
    dispatch(
      updateRedux({
        key: "filter_data",
        value: {
          ...filterData,
          type: e.target.value,
          active: e.target.id,
        },
      })
    );
  };

  const onDataChangeHandler = (key, value) => {
    dispatch(
      updateRedux({
        key: "filter_data",
        value: {
          ...filterData,
          [key]: value,
        },
      })
    );
  };

  const onCancelHandler = () => {
    setShow(false);
    clearFIlters();
  };

  const onSearchHandler = () => {
    dispatch(updateRedux({ key: "home_search", value: false }));
    applyFIlters();
  };

  const onEnterSearch = (e) => {
    if (e.key == "Enter") {
      applyFIlters();
    }
  }

  return (
    <>
      <div className="search__section fadeInUp delay1 w-100 mt-5 ">
        <div className="row justify-content-center">
          <div className="col-md-11 pr-0">
            <input
              type="text"
              className="form-control"
              placeholder="Search Properties, Locations"
              onChange={(e) =>
                //   setFilterData({ ...filterData, keyword: e.target.value })
                onDataChangeHandler("keyword", e.target.value)
              }
              value={filterData?.keyword}
              onKeyDown={(e) => onEnterSearch(e)}
            />
          </div>
          <div className="col-md-1 pl-0 text-left">
            <button className="btn btn-search" onClick={() => onSearchHandler()}>
              <i className="bi bi-search"></i>
            </button>
          </div>
        </div>
        <div className="search__types">
          <div className="btns">
            <button
              key={1}
              className={
                filterData.active === "1"
                  ? "btn btn-sm btn-type active"
                  : "btn btn-sm btn-type"
              }
              id={"1"}
              value={"sale"}
              onClick={handleClick}
            >
              Buy
            </button>

            <button
              key={2}
              value={"rent"}
              className={
                filterData.active === "2"
                  ? "btn btn-sm btn-type active"
                  : "btn btn-sm btn-type"
              }
              id={"2"}
              onClick={handleClick}
            >
              Rent
            </button>
          </div>
          <div className="show__advanced" onClick={advancedHandler}>
            <h6>ADVANCED SEARCH</h6>
            <i className="bi bi-chevron-down"></i>
          </div>
        </div>
      </div>

      {show && (
        <div className="advanced__search fadeInUp delay2 w-100 text-left search__home">
          <div className="row">
            <div className="col-md-3 mb-3">
              <label htmlFor="">Type</label>
              <div className="with-icon">
                <Select
                  value={filterData?.propertyType}
                  options={propertyTypeList}
                  getOptionLabel={(option) => option.name}
                  getOptionValue={(option) => option._id}
                  placeholder="Select"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) =>
                    // setFilterData({ ...filterData, propertyType: e })
                    onDataChangeHandler("propertyType", e)
                  }
                  isClearable={true}
                />
                {
                  !filterData.propertyType && <i className="bi bi-chevron-down"></i>
                }

              </div>
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">Min. Price</label>
              <div className="with-icon">
                {/* <Select
                  value={filterData.minPrice}
                  options={minPriceList}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  placeholder="Select"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) =>
                    // setFilterData({ ...filterData, minPrice: e })
                    onDataChangeHandler("minPrice", e)
                  }
                />
                <i className="bi bi-chevron-down"></i> */}
                <input
                  type="text"
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      if (filterData?.maxPrice && Number(filterData?.maxPrice) < Number(e.target.value)) {
                        setMinErr("Min price should be less than max price")
                        onDataChangeHandler("minPrice", e.target.value);
                      } else {
                        setMinErr("")
                        setMaxErr("")
                        onDataChangeHandler("minPrice", e.target.value);
                      }
                    }
                  }}
                  value={filterData?.minPrice}
                  placeholder="Enter Min Price"
                  className="form-control"
                />
              </div>
              <p className="error">{minErr}</p>
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">Max. Price</label>
              <div className="with-icon">
                <input
                  type="text"
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      if (Number(e.target.value) < Number(filterData?.minPrice)) {
                        setMaxErr("Max price should be greater than min price")
                        onDataChangeHandler("maxPrice", e.target.value);
                      } else {
                        setMinErr("")
                        setMaxErr("")
                        onDataChangeHandler("maxPrice", e.target.value);
                      }
                    }
                  }}
                  value={filterData?.maxPrice}
                  placeholder="Enter Max Price"
                  className="form-control"
                />
              </div>
              <p className="error">{maxErr}</p>
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="">No of Bedroom</label>
              <div className="with-icon">
                <Select
                  value={filterData.bedrooms}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  options={bedroomsList}
                  placeholder="Select"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) => onDataChangeHandler("bedrooms", e)}
                  isClearable={true}
                />
                {
                  !filterData.bedrooms && <i className="bi bi-chevron-down"></i>
                }
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="">Minimum Size （ sq.ft ﹚</label>
              <div className="with-icon">
                {/* <Select
                  value={filterData.minSqft}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  options={minSqftList}
                  placeholder="Select"
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  onChange={(e) =>
                    // setFilterData({ ...filterData, minSqft: e })
                    onDataChangeHandler("minSqft", e)
                  }
                />
                <i className="bi bi-chevron-down"></i> */}
                <input
                  type="text"
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      if (filterData?.maxSqft && Number(filterData?.maxSqft) < Number(e.target.value)) {
                        setMinSqftErr("Min sqft should be less than max sqft")
                        onDataChangeHandler("minSqft", e.target.value);
                      } else {
                        setMinSqftErr("")
                        setMaxSqftErr("")
                        onDataChangeHandler("minSqft", e.target.value);
                      }
                    }
                  }}
                  value={filterData?.minSqft}
                  placeholder="Enter Min Sqft"
                  className="form-control"
                />
              </div>
              <p className="error">{minSqftErr}</p>
            </div>
            <div className="col-md-4 mb-3">
              <label htmlFor="">Maximum Size （ sq.ft ﹚</label>
              <div className="with-icon">
                <input
                  type="text"
                  onChange={(e) => {
                    if (!isNaN(e.target.value)) {
                      if (Number(e.target.value) < filterData?.minSqft) {
                        setMaxSqftErr("Max sqft should be greater than min sqft")
                        onDataChangeHandler("maxSqft", e.target.value);
                      } else {
                        setMaxSqftErr("")
                        setMinSqftErr("")
                        onDataChangeHandler("maxSqft", e.target.value);
                      }
                    }
                  }}
                  value={filterData?.maxSqft}
                  placeholder="Enter Max Sqft"
                  className="form-control"
                />
              </div>
              <p className="error">{maxSqftErr}</p>
            </div>
            {amenitiesList?.length ? (
              <div className="col-md-4 mb-3">
                <label htmlFor="">Amenities</label>
                <div className="with-icon">
                  <MultiSelect
                    options={amenitiesList?.map((ele) => ({
                      label: ele.feature_name,
                      value: ele._id,
                      ...ele,
                    }))}
                    value={filterData.amenities}
                    onChange={(e) => onDataChangeHandler("amenities", e)}
                    labelledBy={"Select"}
                    isClearable={true}
                  />
                </div>
              </div>
            ) : null}

            <div className="col-md-12 text-right">
              <button
                className="btn btn-cancel"
                type="submit"
                onClick={() => onCancelHandler()}
              >
                Cancel
              </button>
              <button
                className="btn btn-apply"
                type="submit"
                onClick={() => onSearchHandler()}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default AdvancedSearchHome;
