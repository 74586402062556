import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import about from '../assets/image/about.png';
import rightArrow from '../assets/image/right_arrow.svg';
import { Link } from 'react-router-dom';
import { webURL } from '../constants/configuration';
import { general_path } from '../redux/commonReducer';
import { useSelector } from 'react-redux';

const AboutSection = ({details}) => {
  const generalPath = useSelector(general_path)

  return (
    <div className='home__about'>
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-md-6 pl-0 fadeInUp delay1">
            <LazyLoadImage src={webURL + generalPath +'/' +details?.about_image} className='w-100' />
          </div>
          <div className="col-md-6 fadeInUp delay1">
            <div className='about-text'>
              <h2>{details?.about_title}</h2>
              <hr />
              <h6>{details?.about_subtitle}</h6>
              <p dangerouslySetInnerHTML={{ __html: details?.about_content,}} />
              <div className="read__more">
                <Link to='/about'><button className='btn btn-primary'>Read more <i className="bi bi-arrow-right"></i></button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutSection