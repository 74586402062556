import React, { useState } from "react";
import mapMarker from "../assets/image/marker.svg";
import Rectangle90 from "../assets/image/Rectangle 90.png";
import { webURL } from "../constants/configuration";
import { NumericFormat } from "react-number-format";

const MapPropertyCard = ({
  activePosition,
  setActivePosition,
  position,
  data,
  thumbnailPath,
  navigate,
}) => {
  return (
    console.log(activePosition, position.lng),
    (
      <div className="marker" style={{ zIndex: activePosition?.lng == position.lng ? 99: 1}}>
        {activePosition?.lng == position.lng && (
          <div className="marker-card">
            <img
              src={
                webURL + thumbnailPath + "/" + data?.property_thumbnail_image
              }
              className="marker-image"
              alt=""
            />
            <div className="tag">
              {data?.buy == "rent" ? (
                "For rent"
              ) : (
                <>
                  For Sale <span className="text-primary">|</span> Ready
                </>
              )}
            </div>
            <div className="marker-details-container">
              <div className="marker-details-title">
                <b className="marker-title">{data?.property_name}</b>
                <br />
                {/* <b className="marker-title">{data?.location[0]?.location_name}</b> */}
              </div>
              <div className="marker-details-price">
                <b className="marker-price">
                  {data?.askprice == "1" ? (
                    <b className="ask__price">Ask for Price</b>
                  ) : (
                    <NumericFormat
                      value={data?.price}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"AED "}
                    />
                  )}
                </b>
                {/* <Link to={`${"/property-details/" + data?._id}`}> */}
                <div
                  className="view-details-button"
                  onClick={() =>
                    navigate(`${"/property-details/" + data?.slug}`)
                  }
                >
                  View Details
                </div>
                {/* </Link> */}
              </div>
            </div>
            <div className="botom-triangle"></div>
          </div>
        )}
        <div style={{ zIndex: -99 }}>
          <img
            src={mapMarker}
            width={35}
            onClick={() => {
              if (position?.lng != activePosition?.lng) {
                console.log(position);
                setActivePosition(position);
              } else {
                setActivePosition();
              }
            }}
            style={{ zIndex: -1 }}
            alt=""
          />
        </div>
      </div>
    )
  );
};

export default MapPropertyCard;
