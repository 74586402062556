import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Home from './pages/Home';
import { Outlet, Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Guide from './pages/Guide';
import GuideDetails from './pages/GuideDetails';
import Header from './components/Header';
import FooterSection from './components/FooterSection';
import Teams from './pages/Teams';
import Contact from './pages/Contact';
import PropertyDetails from './pages/PropertyDetails';
import About from './pages/About';
import JoinTeam from './pages/JoinTeam';
import MarketingAssistance from './pages/MarketingAssistance';
import Wishlist from './pages/Wishlist';
import SearchResults from './pages/SearchResults';
import AllProperties from './pages/AllProperties';
import './responsive.scss';
import AddAgent from './pages/AddAgent';
import PageNotFound from './components/PageNotFound';
function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route element={<><Header/> <Outlet /> <FooterSection /></>}>
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/terms_and_conditions" element={<PrivacyPolicy page="terms" />} />
        <Route path="/guide" element={<Guide />} />
        <Route path="/guide_details/:id" element={<GuideDetails />} />
        <Route path="/teams" element={<Teams />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/property-details/:id" element={<PropertyDetails />} />
        <Route path="/join-team" element={<JoinTeam />} />
        <Route path="/career-details/:id" element={<MarketingAssistance />} />
        <Route path="/wishlists" element={<Wishlist />} />
        <Route path="/search-results" element={<SearchResults />} />
        <Route path="/all-properties" element={<AllProperties />} />
        <Route path="/add-owner" element={<AddAgent />} />
        <Route path="/add-owner" element={<AddAgent />} />
      </Route>
      <Route path="/404Page" element={<PageNotFound />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

AOS.init();

export default App;
