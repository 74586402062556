import React from "react";
import MapComponent from "./MapComponent";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getLocationByProperty,
  getPropertyByLocation,
} from "../redux/actionCreator";
import { location_details } from "../redux/commonReducer";
import { useState } from "react";
import { mapAPI } from "../constants/configuration";

const loadingElement = <div style={{ height: "100%" }} />;

const googleMapURL = `https://maps.googleapis.com/maps/api/js?key=` + mapAPI;

const containerElement = <div style={{ height: "100%", width: "100%" }} />;
const mapElement = <div style={{ height: "100%" }} />;

const LocationSection = () => {
  const dispatch = useDispatch();

  const locations = useSelector(location_details);

  const [activeLocation, setActiveLocation] = useState();

  useEffect(() => {
    dispatch(getLocationByProperty());
  }, []);

  useEffect(() => {
    if (locations?.length) {
      setActiveLocation(locations[0]?._id);
      dispatch(getPropertyByLocation(locations[0]?._id));
    }
  }, [locations]);

  const onPropSearchHandler = (id) => {
    setActiveLocation(id);
    dispatch(getPropertyByLocation(id));
  };

  return (
    <div className="location-section">
      <div className="location-container">
        <div className="location-container-div">
          <b className="location-main-heading">PROPERTY BY LOCATION</b>
          <div className="locations-tile-container" data-aos="fade-up">
            {/* <div className="location-active">Dubai Hills Estate</div>
            <div className="location-inactive">Jumairah Village Circle</div>
            <div className="location-inactive">DIFC</div>
            <div className="location-inactive">Arabian Ranches 2</div>
            <div className="location-inactive">Downtown Dubai</div>
            <div className="location-inactive">City Walk</div>
            <div className="location-inactive">Jumeriah Lake Towers</div>
            <div className="location-inactive-last">Palm Jumeirah</div> */}
            {locations?.map((ele, i) => {
              return (
                <div
                  className={`${
                    ele?._id == activeLocation
                      ? "location-active"
                      : "location-inactive"
                  }`}
                  onClick={() => onPropSearchHandler(ele._id)}
                >
                  {ele?.location_name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="map-container" data-aos="fade-up">
        <div className="map-image">
          <div style={{ height: "100%", width: "100%" }}>
            <MapComponent
              isMarkerShown
              loadingElement={loadingElement}
              googleMapURL={googleMapURL}
              containerElement={containerElement}
              mapElement={mapElement}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationSection;
