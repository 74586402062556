import React, { useEffect, useState } from "react";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { useDispatch, useSelector } from "react-redux";
import { contactEnquiry, getCms } from "../redux/actionCreator";
import { contact_details, contact_loading } from "../redux/commonReducer";
import { mapAPI } from "../constants/configuration";
import { useForm } from "react-hook-form";
import MetaHead from "./metaHead";
const markers = [
  {
    id: 1,
    position: { lat: 25.22679, lng: 55.28402 },
  },
];
function Contact() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();
  const dispatch = useDispatch();

  const contactDetails = useSelector(contact_details);
  const loading = useSelector(contact_loading);

  useEffect(() => {
    dispatch(getCms());
  }, []);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: mapAPI,
  });

  const [activeMarker, setActiveMarker] = useState(null);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const onSubmit = (data) => {
    const filterData = {
      first_name: data?.first_name ?? "",
      last_name: data?.last_name ?? "",
      email: data?.email ?? "",
      phone: data?.phone ?? "",
      message: data?.message ?? "",
    };
    dispatch(
      contactEnquiry(filterData, () => {
        reset();
      })
    );
  };

  const formatMobileNumber = (number) => {
    return `${number?.slice(0, 2)} ${number?.slice(2, 5)} ${number?.slice(
      5,
      9
    )}`;
  };

  const formatLandNumber = (number) => {
    return `${number?.slice(0, 1)} ${number?.slice(1, 4)} ${number?.slice(
      4,
      8
    )}`;
  };

  return (
    <section className="prima__contact pb-0">
      <MetaHead
        title={contactDetails?.meta_title}
        keyword={contactDetails?.meta_keyword}
        description={contactDetails?.meta_description}
      />
      <div className="container">
        <div className="inner__banner fadeInUp delay1">
          <h1>{contactDetails?.title}</h1>
          <p>{contactDetails?.tag}</p>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-5">
            <div className="location__details">
              <h3>{contactDetails?.address_title}</h3>
              <h6
                dangerouslySetInnerHTML={{ __html: contactDetails?.address }}
              ></h6>
            </div>
            <div className="social">
              <ul>
                <li>
                  <a href={`mailto:${contactDetails?.email}`}>
                    <span>Email</span> : {contactDetails?.email}
                  </a>
                </li>
                <li>
                  <a href={`tel:971${contactDetails?.telephone}`}>
                    <span>T</span> :{" "}
                    {"+971 " + formatLandNumber(contactDetails?.telephone)}
                  </a>
                </li>
                <li>
                  <a
                    target="_blank"
                    href={`https://wa.me/971${contactDetails?.mobile}`}
                  >
                    <span>M</span> :{" "}
                    {"+971 " + formatMobileNumber(contactDetails?.mobile)}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-5">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-md-6 mb-4">
                  <input
                    type="text"
                    {...register("first_name", {
                      required: true,
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "First name should have only alphabets",
                      },
                    })}
                    className="form-control"
                    placeholder="First Name *"
                  />
                  {errors.first_name && (
                    <span className="error">
                      {errors.first_name?.message != ""
                        ? errors.first_name?.message
                        : "This field is required"}
                    </span>
                  )}
                </div>
                <div className="col-md-6 mb-4">
                  <input
                    type="text"
                    {...register("last_name", {
                      required: true,
                      pattern: {
                        value: /^[a-zA-Z ]*$/,
                        message: "Last name should have only alphabets",
                      },
                    })}
                    className="form-control"
                    placeholder="Last Name *"
                  />
                  {errors.last_name && (
                    <span className="error">
                      {errors.last_name?.message != ""
                        ? errors.last_name?.message
                        : "This field is required"}
                    </span>
                  )}
                </div>
                <div className="col-md-12 mb-4">
                  <input
                    type="email"
                    {...register("email", {
                      required: true,
                      pattern: {
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
                        message: "Entered value does not match email format",
                      },
                    })}
                    className="form-control"
                    placeholder="Email *"
                  />
                  {errors.email && (
                    <span className="error">
                      {errors.email?.message != ""
                        ? errors.email?.message
                        : "This field is required"}
                    </span>
                  )}
                </div>
                <div className="col-md-12 mb-4">
                  <input
                    type="text"
                    {...register("phone", {
                      required: true,
                      pattern: {
                        value: /^[0-9]{9,13}$/,
                        message:
                          "Invalid Phone Number (Phone field should have numbers only)",
                      },
                    })}
                    className="form-control"
                    placeholder="Phone Number *"
                  />
                  {errors.phone && (
                    <span className="error">
                      {errors.phone?.message != ""
                        ? errors.phone?.message
                        : "This field is required"}
                    </span>
                  )}
                </div>
                <div className="col-md-12 mb-4">
                  <textarea
                    className="form-control"
                    {...register("message", { required: true })}
                    placeholder="Please tell us how we can assist you *"
                    rows="4"
                  ></textarea>
                  {errors.message && (
                    <span className="error">This field is required</span>
                  )}
                </div>
                <div className="col-md-12 text-right">
                  <button
                    type="submit"
                    className="btn btn-submit"
                    disabled={loading}
                  >
                    SUBMIT NOW{" "}
                    {loading ? (
                      <i className="spinner-border spinnerStyle" style={{color: "white"}}></i>
                    ) : (
                      <i className="bi bi-arrow-right"></i>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="google__map">
        {isLoaded ? (
          <GoogleMap
            center={{ lat: 25.22679, lng: 55.28402 }}
            zoom={10}
            onClick={() => setActiveMarker(null)}
            mapContainerStyle={{ width: "100%", height: "600px" }}
          >
            {markers.map(({ id }) => (
              <MarkerF
                key={id}
                zoom={{
                  lat: Number(Number(contactDetails?.latitude)),
                  lng: Number(Number(contactDetails?.longitude)),
                }}
                position={{
                  lat: Number(Number(contactDetails?.latitude)),
                  lng: Number(Number(contactDetails?.longitude)),
                }}
                onClick={() => handleActiveMarker(id)}
                icon={{
                  url: "/assets/img/icons/marker.svg",
                }}
              ></MarkerF>
            ))}
          </GoogleMap>
        ) : null}
      </div>
    </section>
  );
}

export default Contact;
