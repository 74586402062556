import React, { useEffect } from "react";
import { useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Navigation } from "swiper";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  careerEnquiry,
  getCareer,
  getCareerTitles,
} from "../redux/actionCreator";
import {
  career_lists,
  general_details,
  jobtitles,
  join_team_loading,
  property_types,
} from "../redux/commonReducer";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { webURL } from "../constants/configuration";

function JoinTeam() {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    resetField,
  } = useForm({ defaultValues: { position: "" } });

  const dispatch = useDispatch();

  const careerLists = useSelector(career_lists);
  const data = useSelector(general_details);
  const loading = useSelector(join_team_loading);

  const [resume, setResume] = useState("");
  const [resumeErr, setResumeErr] = useState("");

  const jobLists = useSelector(jobtitles);
  const jobNames = [
    {
      value: "other",
      label: "Other",
    },
  ];

  jobLists?.map((ele) => {
    jobNames.unshift({
      value: ele?._id,
      label: ele?.position_name,
    });
  });

  useEffect(() => {
    dispatch(getCareerTitles());
    dispatch(getCareer());
  }, []);

  const onFileChangeHandler = (e) => {
    setResume("");
    const file = e.target.files[0];
    const fileType = file.name
      .substr(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    if (["pdf", "PDF", "docx", "doc"].includes(fileType)) {
      setResume(e.target.files[0]);
      setResumeErr("");
    } else {
      setResumeErr("Please upload file with pdf, docx, doc formats");
    }
  };

  const onSubmit = (data, e) => {
    console.log(data, "data");
    if (resume?.length == 0) {
      setResumeErr("Please upload resume");
    } else {
      let formData = new FormData();
      formData.append("first_name", data?.first_name);
      formData.append("last_name", data?.last_name);
      formData.append("email", data?.email);
      formData.append("phone", data?.phone);
      formData.append("cover_letter", data?.cover_letter);
      if (jobLists?.length != 0) {
        if (data?.position != "other") {
          formData.append("position", data?.position);
        }
      }
      formData.append("resume", resume);
      console.log(formData);
      dispatch(
        careerEnquiry(formData, () => {
          reset();
          setResume("");
          setResumeErr("");
        })
      );
    }
  };

  return (
    console.log(jobLists),
    (
      <>
        <section className="prima__join_team">
          <div className="container">
            <div className="inner__banner fadeInUp delay1">
              <h1>{data?.career_title}</h1>
              <p>{data?.career_tagline}</p>
            </div>
            <div className="submit__form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="First Name*"
                      {...register("first_name", {
                        required: true,
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "First name should have only alphabets",
                        },
                      })}
                      aria-invalid={errors.first_name ? "true" : "false"}
                    />
                    {errors.first_name && (
                      <span className="error">
                        {errors.first_name?.message != ""
                          ? errors.first_name?.message
                          : "This field is required"}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Last Name*"
                      {...register("last_name", {
                        required: true,
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Last name should have only alphabets",
                        },
                      })}
                      aria-invalid={errors.last_name ? "true" : "false"}
                    />
                    {errors.last_name && (
                      <span className="error">
                        {errors.last_name?.message != ""
                          ? errors.last_name?.message
                          : "This field is required"}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email*"
                      {...register("email", {
                        required: true,
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
                          message: "Entered value does not match email format",
                        },
                      })}
                      aria-invalid={errors.email ? "true" : "false"}
                    />
                    {errors.email && (
                      <span className="error">
                        {errors.email?.message != ""
                          ? errors.email?.message
                          : "This field is required"}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6 mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Phone*"
                      {...register("phone", {
                        required: true,
                        pattern: {
                          value: /^[0-9]{9,13}$/,
                          message:
                            "Invalid Phone Number (Phone field should have numbers only)",
                        },
                      })}
                      aria-invalid={errors.phone ? "true" : "false"}
                    />
                    {errors.phone && (
                      <span className="error">
                        {errors.phone?.message != ""
                          ? errors.phone?.message
                          : "This field is required"}
                      </span>
                    )}
                  </div>
                  {jobLists?.length != 0 ? (
                    <div className="col-md-12 mb-3">
                      <div className="with-icon">
                        <Controller
                          control={control}
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value, name, ref },
                          }) => (
                            <Select
                              inputRef={ref}
                              value={
                                jobNames?.find((c) => c.value === value) ?? ""
                              }
                              name={name}
                              options={jobNames}
                              placeholder="Preferred Position*"
                              components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                              }}
                              onChange={(selectedOption) => {
                                onChange(selectedOption.value);
                              }}
                            />
                          )}
                          name={"position"}
                        />
                        <i className="bi bi-chevron-down"></i>
                      </div>
                      {errors.position && (
                        <span className="error">This field is required</span>
                      )}
                    </div>
                  ) : null}

                  <div className="col-md-12 mb-2">
                    <label htmlFor="">Cover Letter*</label>
                    <textarea
                      name=""
                      rows="5"
                      placeholder="Please tell us more about yourself and elaborate on your motivation for becoming a part of our company."
                      className="form-control"
                      {...register("cover_letter", {
                        required: true,
                      })}
                      aria-invalid={errors.cover_letter ? "true" : "false"}
                    ></textarea>
                    {errors.cover_letter && (
                      <span className="error">
                        {errors.cover_letter?.message != ""
                          ? errors.cover_letter?.message
                          : "Please tell us more about yourself and describe your motivation for seeking this position"}
                      </span>
                    )}
                  </div>
                  <div className="col-md-12 mb-2">
                    <div className="d-flex align-items-end">
                      <div className="add-file">
                        {/* <input
                        type="file"
                        id="contact_file"
                        {...register("resume", { required: true })}
                        name="file"
                      /> */}
                        {/* <a href="" className="add-file-btn">
                        Upload CV
                      </a> */}
                        <input
                          type="file"
                          name="file"
                          id="contact_file"
                          onClick={(e) => (e.target.value = null)}
                          accept=".pdf, .PDF, .docx, .doc"
                          onChange={(e) => onFileChangeHandler(e)}
                        />
                        <span>
                          {!resume?.name ? "Upload CV" : resume?.name}
                        </span>
                      </div>
                      <button
                        className="btn btn-upload"
                        type="button"
                        onClick={() =>
                          document?.getElementById("contact_file")?.click()
                        }
                      >
                        Upload <i className="bi bi-chevron-right"></i>
                      </button>
                    </div>
                    {resumeErr && <span className="error">{resumeErr}</span>}
                  </div>
                  <div className="col-md-4 mt-4">
                    <button
                      className="btn-primary"
                      type="submit"
                      disabled={loading}
                    >
                      APPLY NOW{" "}
                      {loading ? (
                        <i className="spinner-border spinnerStyle" style={{color: "black"}}></i>
                      ) : (
                        <i className="bi bi-arrow-right"></i>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </section>
        {!careerLists?.length == 0 && (
          <section
            className="active__jobs"
            style={{
              backgroundImage: `url("${
                webURL + "general/" + data?.background_image
              }")`,
            }}
          >
            <div className="container">
              <div className="job-head">
                <h2>ACTIVE JOBS</h2>
              </div>
              <Swiper
                modules={[Navigation]}
                className="mySwiper"
                centerInsufficientSlides={true}
                breakpoints={{
                  640: {
                    slidesPerView: 1,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }}
                navigation={{
                  nextEl: ".bi-chevron-right",
                  prevEl: ".bi-chevron-left",
                }}
                spaceBetween={0}
              >
                <div className="swiper-btns">
                  <i className="bi bi-chevron-left"></i>
                  <i className="bi bi-chevron-right"></i>
                </div>
                {careerLists?.map((ele, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <div className="vacancy__grid">
                        <div className="vacancy__header">
                          <h2>{ele?.position_name}</h2>
                        </div>
                        <hr />
                        <div className="vacancy__details">
                          {/* <div className="grid__item">
                                                        <div className="detail-head">
                                                            <h4>Location</h4>
                                                            <h6>{ele?.location}</h6>
                                                        </div>
                                                        <div className="detail-head">
                                                            <h4>Working Time</h4>
                                                            <h6>{ele?.location}</h6>
                                                        </div>
                                                    </div> */}
                          <div className="grid__item">
                            <div className="detail-head">
                              <h4>Department</h4>
                              <h6>{ele?.department}</h6>
                            </div>
                            <div className="detail-head">
                              <h4>Employment Term</h4>
                              <h6>
                                {ele?.employment_term == "full_time"
                                  ? "Full Time"
                                  : "" || ele?.employment_term == "part_time"
                                  ? "Part Time"
                                  : "" || ele?.employment_term == "remote"
                                  ? "Remote"
                                  : "" || ele?.employment_term == "hybrid"
                                  ? "Hybrid"
                                  : ""}
                              </h6>
                            </div>
                          </div>
                          <div className="grid__item">
                            <div className="detail-head">
                              <h4>Description</h4>
                              <h6
                                className="h-120"
                                dangerouslySetInnerHTML={{
                                  __html: ele?.about_job,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="view__detail">
                          <Link to={`/career-details/${ele?.slug}`}>
                            VIEW DETAILS
                          </Link>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </section>
        )}
      </>
    )
  );
}

export default JoinTeam;
