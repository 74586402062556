import React from 'react'
import { Link } from 'react-router-dom'

function PageNotFound() {
    return (
        <section className="page-section">
            <div className="full-width-screen">
                <div className="container-fluid">
                    <div className="content-detail">
                        <h1 className="global-title"><span>404</span></h1>
                        <h4 className="sub-title">Oops!</h4>
                        <p className="detail-text">We're sorry,  The page you were looking for doesn't exist anymore.</p>
                        <div className="back-btn">
                           <Link to='/'> <button className='btn btn-primary'>Back to Home</button> </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PageNotFound
