import React, { useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  general_details,
  loading_spinner,
  thumbnail_path,
  updateRedux,
  user_logged,
  wishListData,
  wishListPageCount,
} from "../redux/commonReducer";
import { useEffect } from "react";
import {
  addWishList,
  deleteWishList,
  getWishList,
} from "../redux/actionCreator";
import { webURL } from "../constants/configuration";
import { Link, useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import NoData from "../components/NoData";
import LoadingSpinner from "../components/LoadingSpinner";
import { toast } from "react-toastify";

function Wishlist() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const data = useSelector(wishListData);
  const generalDetails = useSelector(general_details);
  const thumbnailPath = useSelector(thumbnail_path);
  const loading = useSelector(loading_spinner);
  const pageCount = useSelector(wishListPageCount);
  const userLogged = useSelector(user_logged);

  const [page, setPage] = useState(0);

  useEffect(() => {
    if (token) {
      let formData = new FormData();
      formData.append("perPage", 12);
      formData.append("page", page);
      dispatch(getWishList(formData));
    } else {
      // toast.error("Please Login", {
      //   position: "bottom-center",
      //   autoClose: 3000,
      // });
      navigate("/");
    }
  }, [userLogged, token]);

  const scrollToTop = () => {
    // window.scrollTo({top: 200});
    if(!window.matchMedia("(min-width: 768px)")?.matches){
      window.scrollTo({top: 0});
      const divElement = document.getElementById("propertySection");
      divElement?.scrollIntoView({ behavior: "smooth" });
    }else {
      window.scrollTo({top: 0});
    }
  }

  const pagination = useRef();

  const handlePageChange = (e) => {
    setPage(e.selected);
    console.log(e.selected);
    if (e.selected >= 0) {
      // let value = {
      //   page : e.selected
      // }
      let formData = new FormData();
      formData.append("perPage", 12);
      formData.append("page", e.selected);
      dispatch(getWishList(formData,()=>{
        scrollToTop()
      }));
    }
  };

  const removeWishlistHandler = (id) => {
    let wishListData = data
    if (token != null) {
      let data = {
        property_id: id,
      };
      dispatch(
        deleteWishList(data, () => {
          // let value = {
          //   page: page,
          // };
          // dispatch(getWishList(value));
          if (wishListData.length === 1) {
            setPage(0)
            let formData = new FormData();
            formData.append("perPage", 12);
            formData.append("page", 0);
            dispatch(getWishList(formData));
          } else {
            let formData = new FormData();
            formData.append("perPage", 12);
            formData.append("page", page);
            dispatch(getWishList(formData));
          }
        })
      );
    } else {
      // toast.error("Please Login", {
      //   position: "bottom-center",
      //   autoClose: 3000,
      // });
      dispatch(updateRedux({ key: 'login_modal_show', value: true }))
    }
  };

  const addWishlistHandler = (id) => {
    console.log(token != null);
    if (token != null) {
      let data = {
        property_id: id,
      };
      dispatch(
        addWishList(data, () => {
          let formData = new FormData();
          formData.append("perPage", 12);
          formData.append("page", page);
          dispatch(getWishList(formData));
        })
      );
    } else {
      // toast.error("Please Login", {
      //   position: "bottom-center",
      //   autoClose: 3000,
      // });
      dispatch(updateRedux({ key: 'login_modal_show', value: true }))
    }
  };
  return (
    <section className="prima__wishlists">
      <div className="container">
        <div className="inner__banner fadeInUp delay1">
          <h1>{generalDetails?.wishlist_title}</h1>
          <p>{generalDetails?.wishlist_tagline}</p>
        </div>
        <Row className="justify-content-md-center ">
          {data?.map((ele, index) => (
            <Col xs={12} sm={6} lg={4} className="justify-content-md-center">
              <div className="property__lists">
                <div className="property__img">
                  <Link to={`${"/property-details/" + ele?.slug}`}>
                    <img
                      src={
                        webURL +
                        thumbnailPath +
                        "/" +
                        ele?.property_thumbnail_image
                      }
                      alt=""
                      className="pointer"
                    />
                  </Link>
                  <div className="property__tag">
                    <div className="tag">
                      <p>
                        {ele?.buy == "rent" ? (
                          "For rent"
                        ) : (
                          <>
                            For Sale <span>|</span> Ready
                          </>
                        )}{" "}
                      </p>
                    </div>
                    <div className="whishlist">
                      {ele?.is_favourite ? (
                        <i
                          className="bi bi-heart-fill pointer"
                          onClick={() => removeWishlistHandler(ele._id)}
                        ></i>
                      ) : (
                        <i
                          className="bi bi-heart pointer"
                          onClick={() => addWishlistHandler(ele._id)}
                        ></i>
                      )}
                    </div>
                  </div>
                </div>
                <Link to={`${"/property-details/" + ele?.slug}`}>
                  <div className="property__title pointer">
                    <h2>{ele?.property_name}</h2>
                    <div className="property__price">
                      {ele?.askprice == "1" ? (
                        <h3 className="ask__price">ask for price</h3>
                      ) : (
                        <h3>
                          <NumericFormat
                            value={ele?.price}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"AED "}
                          />
                        </h3>
                      )}

                      <h6>{ele?.residential}</h6>
                    </div>
                    <div className="property__features">
                      <ul>
                        <li>
                          {ele?.residential == "commercial" ? (
                            <>{ele?.pantry} Pantry</>
                          ) : ele?.bedroom == "0" ||
                            ele?.bedroom == "Studio" ? (
                            "Studio"
                          ) : (
                            <> {ele?.bedroom} Bedroom</>
                          )}{" "}
                        </li>
                        <li>{ele?.bathroom} Bathroom</li>
                        <li className="lowercase">{ele?.sqft} sq.ft.</li>
                      </ul>
                    </div>
                  </div>
                </Link>
              </div>
            </Col>
          ))}
        </Row>
        {data?.length == 0 ? <NoData /> : ""}
        {pageCount > 1 ? (
          <div className="pagination__style2 mb-5">
            <ReactPaginate
              ref={pagination}
              pageCount={pageCount}
              pageRangeDisplayed={4}
              forcePage={page}
              marginPagesDisplayed={1}
              onPageChange={(e) => handlePageChange(e)}
              containerClassName="pagination"
              activeClassName="active"
              pageLinkClassName="page-link"
              breakLinkClassName="page-link"
              nextLinkClassName="page-link"
              previousLinkClassName="page-link"
              pageClassName="page-item"
              breakClassName="page-item"
              nextClassName="page-item"
              previousClassName="page-item"
              previousLabel={<i className="bi bi-chevron-left"></i>}
              nextLabel={<i className="bi bi-chevron-right"></i>}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  );
}

export default Wishlist;
