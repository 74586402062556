import React, { useEffect, useState } from "react";
import "./Header.css";
import logo from "../assets/image/logo.svg";
import logoMobile from "../assets/image/logo_mobile.png";
import search from "../assets/image/search.svg";
import profile from "../assets/image/profile.svg";
import menu from "../assets/image/menu.svg";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Modal } from "react-bootstrap";
import Login from "./Login";
import ModalComponent from "./ModalComponent";
import { useDispatch, useSelector } from "react-redux";
import { forgot_password, login_modal_show, register_state, updateRedux, wishListCount } from "../redux/commonReducer";
import RegisterForm from "./RegisterForm";
import { allSearchResults, getCms, getHome, getWishListCount } from "../redux/actionCreator";
import ForgotPassword from "./forgotPassword";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const logged = localStorage.getItem("user_logged");
  const token = localStorage.getItem("token");
  const [modalActive, setModalActive] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [menuOpen, setmenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [active, setActive] = useState("");
  const [allkeyword, setAllKeyword] = useState("");

  // USESELECTORS
  const registerState = useSelector(register_state);
  const forgotPassword = useSelector(forgot_password);
  const countWishlist = useSelector(wishListCount);
  const loginModalShow = useSelector(login_modal_show)

  useEffect(() => {
    if (token) {
      dispatch(getWishListCount())
    }
  }, [token])


  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 50);
    });
    dispatch(getHome());
    dispatch(getCms());
  }, []);

  const menuOpenHandler = () => {
    setmenuOpen(!menuOpen);
    setSearchOpen(false);
  };
  const openSearch = () => {
    setSearchOpen(!searchOpen);
    setmenuOpen(false);
  };

  const signInpopup = () => {
    setShow(true);
    dispatch(updateRedux({ key: 'login_modal_show', value: true }))
    setmenuOpen(false);
  };

  const signOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("name");
    localStorage.removeItem("email");
    localStorage.removeItem("phone");
    localStorage.removeItem("user_logged");
    dispatch(updateRedux({ key: 'user_logged', value: false }))
    setmenuOpen(false);
    window.location = "/";
  };

  const propertyHandler = (type) => {
    setActive(type);
    dispatch(updateRedux({ key: "property_type_value", value: type }));
    navigate("/all-properties");
  };
  // ALL SEARCH

  const handleSearch = (e) => {
    navigate('/search-results', {
      state: {
        key: allkeyword
      }
    })
  }


  useEffect(() => {
    if (!allkeyword == '') {
      const keyDownHandler = event => {
        if (event.key === 'Enter') {
          event.preventDefault();
          handleSearch();
        }
      };
      document.addEventListener('keydown', keyDownHandler);
      return () => {
        document.removeEventListener('keydown', keyDownHandler);
      };
    }

  }, [allkeyword]);


  const onHomeHandler = (e) => {
    navigate("/")
    // window.location.reload(true)
  }

  return (
    <>
      <ModalComponent
        modalActive={modalActive}
        setModalActive={setModalActive}
      />
      <Modal
        dialogClassName="modal-40w"
        className="login__modal"
        show={loginModalShow}
        centered
        onHide={() => {
          setShow(false)
          dispatch(updateRedux({ key: 'register_state', value: false }))
          dispatch(updateRedux({ key: 'forgot_password', value: false }))
          dispatch(updateRedux({ key: 'login_modal_show', value: false }))
        }}
      >
        <div className="close" onClick={() => {
          setShow(false)
          dispatch(updateRedux({ key: 'register_state', value: false }))
          dispatch(updateRedux({ key: 'forgot_password', value: false }))
          dispatch(updateRedux({ key: 'login_modal_show', value: false }))
        }}>
          <i className="bi bi-x"></i>
        </div>
        <Modal.Body>
          {(registerState && !forgotPassword) ? (
            <RegisterForm show={show} setShow={setShow} />
          ) : (!registerState && !forgotPassword) ? (
            <Login show={show} setShow={setShow} />
          ) : <ForgotPassword show={show} setShow={setShow} />}

        </Modal.Body>
      </Modal>
      <div
        className={
          scroll
            ? "header fixed-top"
            : window.location.pathname == "/"
              ? "bg-transparent header"
              : "header"
        }
      >
        {/* <Link to="/"> */}
        <LazyLoadImage src={logo} className="logo pointer" alt="logo" onClick={() => onHomeHandler()} />
        {/* </Link> */}
        {/* <Link to="/"> */}
        {" "}
        <LazyLoadImage src={logoMobile} className="logo-mobile pointer" alt="logo" onClick={() => onHomeHandler()} />
        {/* </Link> */}
        <div
          className={menuOpen ? "menu-open icons-container" : "icons-container"}
        >
          {searchOpen ? (
            <i
              className="bi bi-x-lg text-white"
              style={{ cursor: "pointer" }}
              onClick={openSearch}
            ></i>
          ) : (
            <img
              src={search}
              alt="search"
              loading="lazy"
              onClick={openSearch}
              className="icons-image"
            />
          )}
          {
            token ?
              <Link to='/wishlists'>
                <div className="wishlists header_wishlists">
                  {
                    !countWishlist == 0 ?
                      <span>{countWishlist}</span> : ""
                  }

                  <i className="bi bi-heart"></i>
                </div>
              </Link>

              : ""
          }
          {
            !token ?
              <img
                src={profile}
                alt="profile"
                className="icons-image"
                onClick={() => {
                  setShow(true)
                  dispatch(updateRedux({ key: 'login_modal_show', value: true }))
                }}
              /> : ""
          }


          <img
            src={menu}
            alt="menu"
            className="icons-image menu-img"
            onClick={menuOpenHandler}
          />
          {menuOpen && (
            <>
              <i
                className="bi bi-x-lg menu-close"
                onClick={menuOpenHandler}
              ></i>
              <div className="menu__items" onClick={menuOpenHandler}>
                <ul>
                  <li>
                    <NavLink to="/">Home</NavLink>
                  </li>
                  <li>
                    <NavLink to="/about">About us</NavLink>
                  </li>
                  <li>
                    <NavLink to="/guide">Guide</NavLink>
                  </li>
                  <li>
                    <NavLink to="/join-team">Join Our team</NavLink>
                  </li>
                  <li>
                    <NavLink to="/contact">contact us</NavLink>
                  </li>
                  <li onClick={() => setModalActive(true)}>
                    List your property
                  </li>
                </ul>
                <ul>
                  <li onClick={token ? signOut : signInpopup}>
                    {token ? "Sign Out" : "Sign in"}
                  </li>

                  {/* {logged && <li><NavLink to="/add-owner">Add owner</NavLink></li> } */}
                  <li>
                    <span
                      onClick={() => propertyHandler("")}
                      className={`${active == "" &&
                        location?.pathname?.includes("all-properties")
                        ? "active-type"
                        : ""
                        }`}
                    >
                      All properties
                    </span>
                  </li>
                  <li>
                    <span
                      onClick={() => propertyHandler("premium")}
                      className={`${active == "premium" &&
                        location?.pathname?.includes("all-properties")
                        ? "active-type"
                        : ""
                        }`}
                    >
                      Premium
                    </span>
                  </li>
                  <li>
                    <span
                      onClick={() => propertyHandler("sale")}
                      className={`${active == "sale" &&
                        location?.pathname?.includes("all-properties")
                        ? "active-type"
                        : ""
                        }`}
                    >
                      Sale
                    </span>
                  </li>
                  <li>
                    <span
                      onClick={() => propertyHandler("rent")}
                      className={`${active == "rent" &&
                        location?.pathname?.includes("all-properties")
                        ? "active-type"
                        : ""
                        }`}
                    >
                      Rent
                    </span>
                  </li>
                </ul>
              </div>
            </>
          )}
          {searchOpen && (
            <div className="header__search">
              <input
                type="text"
                className="form-control"
                placeholder="Type Something.."
                onChange={(e => setAllKeyword(e.target.value))}
              />

              <button className="btn btn-search" type="submit" onClick={handleSearch}>
                <i className="bi bi-search"></i>
              </button>

            </div>

          )}
        </div>
      </div>
    </>
  );
};

export default Header;
