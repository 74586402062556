import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import rightArrow from "../assets/image/right_arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { webURL } from "../constants/configuration";
import { NumericFormat } from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { thumbnail_path, updateRedux, user_logged } from "../redux/commonReducer";
import { addWishList, deleteWishList, getHome } from "../redux/actionCreator";
import { toast } from "react-toastify";

const PremiumPropertiesSection = ({ premiumProperties }) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const thumbnailPath = useSelector(thumbnail_path);
  const userLogged = useSelector(user_logged)


  const propertyHandler = (type) => {
    dispatch(updateRedux({ key: "property_type_value", value: type }));
    navigate("/all-properties");
  };

  const removeWishlistHandler = (id) => {
    if (token != null) {
      let data = {
        property_id: id,
      };
      dispatch(
        deleteWishList(data, () => {
          dispatch(getHome());
        })
      );
    } else {
      // toast.error("Please Login", {
      //   position: "bottom-center",
      //   autoClose: 3000,
      // });
      dispatch(updateRedux({ key: 'login_modal_show', value: true }))
    }
  };

  const addWishlistHandler = (id) => {
    if (token != null) {
      let data = {
        property_id: id,
      };
      dispatch(
        addWishList(data, () => {
          dispatch(getHome());
        })
      );
    } else {
      dispatch(updateRedux({ key: 'login_modal_show', value: true }))
      // toast.error("Please Login", {
      //   position: "bottom-center",
      //   autoClose: 3000,
      // });
    }
  };

  console.log("premiumProperties", premiumProperties);
  return (
    <div className="premium-properties-section">
      {!premiumProperties?.length == 0 && (
        <>
          <h2 className="premium-properties-text">PREMIUM PROPERTIES</h2>
          <div className="premium-properties-underline"></div>
          <Container className="premium-data-container">
            <Row className="justify-content-md-center ">
              {premiumProperties.map((ele, index) => (
                <Col
                  xs={12}
                  sm={6}
                  lg={4}
                  className="justify-content-md-center"
                >
                  <div className="property__lists">
                    <div className="property__img">
                      <Link to={`${"/property-details/" + ele?.slug}`}>
                        <img
                          src={
                            webURL +
                            thumbnailPath +
                            "/" +
                            ele?.property_thumbnail_image
                          }
                          alt=""
                        />
                      </Link>
                      <div className="property__tag">
                        {/* <div className="tag"><p>{ele?.buy} <span>|</span> Ready</p></div> */}
                        <div className="tag">
                          <p>
                            {ele?.buy == "rent" ? (
                              "For rent"
                            ) : (
                              <>
                                For Sale <span>|</span> Ready
                              </>
                            )}{" "}
                          </p>
                        </div>
                        <div className="whishlist">
                          {ele?.is_favourite ? (
                            <i
                              className="bi bi-heart-fill pointer"
                              onClick={() => removeWishlistHandler(ele._id)}
                            ></i>
                          ) : (
                            <i
                              className="bi bi-heart pointer"
                              onClick={() => addWishlistHandler(ele._id)}
                            ></i>
                          )}
                        </div>
                      </div>
                    </div>
                    <Link to={`${"/property-details/" + ele?.slug}`}>
                      <div className="property__title">
                        <h2>{ele?.property_name}</h2>
                        <div className="property__price">
                          {ele?.askprice == "1" ? (
                            <h3 className="ask__price">ask for price</h3>
                          ) : (
                            <h3>
                              <NumericFormat
                                value={ele?.price}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"AED "}
                              />
                            </h3>
                          )}

                          <h6>{ele?.residential}</h6>
                        </div>
                        <div className="property__features">
                          <ul>
                            <li>
                              {ele?.residential == "commercial" ? (
                                <>{ele?.pantry} Pantry</>
                              ) : ele?.bedroom == "0" ||
                                ele?.bedroom == "Studio" ? (
                                "Studio"
                              ) : (
                                <> {ele?.bedroom} Bedroom</>
                              )}{" "}
                            </li>
                            <li>{ele?.bathroom} Bathroom</li>
                            <li className="lowercase">{ele?.sqft} sq.ft.</li>
                          </ul>
                        </div>
                      </div>
                    </Link>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
          <div className="view-all-button" onClick={() => propertyHandler("premium")}>
            <b className="view-all-text">
              VIEW ALL
            </b>
            <img src={rightArrow} width={20} />
          </div>
        </>
      )}
    </div>
  );
};

export default PremiumPropertiesSection;
