import React, { useState } from "react";
import Header from "../components/Header";
import FooterSection from "../components/FooterSection";
import { Button, Col, Container, Row } from "react-bootstrap";
import GuideImage from "../assets/image/guide-image.png";
import "./Guide.css";
import upArrow from "../assets/image/ios-arrow-up.svg";
import rightArrow from "../assets/image/right_arrow.svg";
import GuideMapImage from "../assets/image/guide-map.png";
import facebook from "../assets/image/fbblack.svg";
import twitter from "../assets/image/twitterblack.svg";
import instagram from "../assets/image/instagramblack.svg";
import linkedin from "../assets/image/linkedinblack.svg";
import Pagination from "react-bootstrap/Pagination";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGuide } from "../redux/actionCreator";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import {
  general_details,
  guide_lists,
  guide_pageCount,
  guide_pageStatus,
  guide_path,
} from "../redux/commonReducer";
import { useEffect } from "react";
import moment from "moment";
import { currentURL, webURL } from "../constants/configuration";
import ReactPaginate from "react-paginate";
import { useRef } from "react";

// items.push(<Pagination.Next key="next" onClick={() => {}} className='pagination-button'/>)

const Guide = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const GuideItems = useSelector(guide_lists);
  const data = useSelector(general_details);
  const guidePath = useSelector(guide_path);
  const pageStatus = useSelector(guide_pageStatus);
  const pageCount = useSelector(guide_pageCount);

  const pagination = useRef();
  const [page, setPage] = useState("");

  useEffect(() => {
    let formData = new FormData();
    formData.append("page", 0);
    formData.append("perPage", 5);
    dispatch(getGuide(formData, () => {
      scrollToTop()
    }));
  }, []);

  const handlePageChange = (e) => {
    setPage(e.selected);
    if (e.selected >= 0) {
      let formData = new FormData();
      formData.append("perPage", 5);
      formData.append("page", e.selected);
      dispatch(getGuide(formData, () => {
        scrollToTop()
      }));
    }
  };

  const scrollToTop = () => {
    // window.scrollTo({top: 200});
    if (!window.matchMedia("(min-width: 768px)")?.matches) {
      window.scrollTo({ top: 400 });
      const divElement = document.getElementById("propertySection");
      divElement?.scrollIntoView({ behavior: "smooth" });
    } else {
      window.scrollTo({ top: 0 });
    }
  }
  return (
    <div>
      <Container className="guide-container">
        <h2 className="guide-title" data-aos="fade-up">
          {data?.guide_title}
        </h2>
        <div className="guide-title-underline" data-aos="fade-up"></div>
        <div className="guide-introduction" data-aos="fade-up">
          {data?.guide_tagline}
          {/* viverra ipsum. Suscipit adipiscing bibendum est ultricies integer quis. */}
        </div>
        <Container>
          <Row className="sm-reverse" id="propertySection">
            {GuideItems?.slice(0, 1)?.map((ele) => {
              return (
                <>
                  <Col
                    sm={12}
                    md={12}
                    lg={6}
                    className="guide-page-introduction"
                    data-aos="fade-up"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`${"/guide_details/" + ele?.slug}`)}
                  >
                    <div className="guide-breadcrumbs">
                      <span
                        className="breadcrumbs-link pointer"
                        onClick={() => navigate("/")}
                      >
                        Home
                      </span>
                      <img src={upArrow} className="guide-breadcrumbs-icon" />
                      <span className="breadcrumbs-link">Guide</span>
                    </div>
                    {
                      page == '' && <div className="guide-tag">Latest</div>
                    }

                    <h4 className="guide-page-title">{ele?.guide_title}</h4>
                    <div className="guide-subtitle">
                      <p
                        dangerouslySetInnerHTML={{ __html: ele?.short_description }}
                      ></p>
                    </div>
                    <Link
                      to={`${"/guide_details/" + ele?.slug}`}
                      className="underline-0"
                    >
                      <button className="btn btn-readmore">
                        Read more <img src={rightArrow} width={20} />
                      </button>
                    </Link>
                  </Col>
                  <Col
                    sm={12}
                    md={12}
                    lg={6}
                    className="guide-page-image"
                    data-aos="fade-up"
                  >
                    <img
                      src={webURL + guidePath + "/" + ele?.guide_image}
                      className="guide-image"
                    />
                    <div className="date-container">
                      {moment(ele?.guide_date)?.format("DD MMM YYYY")}
                    </div>
                  </Col>
                </>
              );
            })}
          </Row>
        </Container>
        <Container className="guide-card-container">
          <Row>
            {GuideItems?.slice(1)?.map((ele, i) => {
              return (
                <Col md={12} lg={6} className="guide-card" data-aos="fade-up" >
                  <img
                    className="inner__img"
                    src={webURL + guidePath + "/" + ele?.guide_image}
                    width={"100%"}
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`${"/guide_details/" + ele?.slug}`)}
                  />
                  <h2 className="guide-card-title" style={{ cursor: "pointer" }} onClick={() => navigate(`${"/guide_details/" + ele?.slug}`)}>{ele?.guide_title}</h2>
                  <div className="guide-card-details" style={{ cursor: "pointer" }} onClick={() => navigate(`${"/guide_details/" + ele?.slug}`)}>
                    <p
                      dangerouslySetInnerHTML={{ __html: ele?.short_description }}
                    ></p>
                  </div>
                  <div className="guide-card-footer">
                    <Link
                      to={`${"/guide_details/" + ele?.slug}`}
                      className="underline-0"
                    >
                      <button className="btn btn-readmore">
                        Read more <img src={rightArrow} width={20} />
                      </button>
                    </Link>
                    <div className="social-media-links guide-social-media-links">
                      <FacebookShareButton
                        title={ele?.guide_title}
                        url={`${currentURL + "guide_details/" + ele?.slug}`}
                      >
                        <img src={facebook} className="social-media" />
                      </FacebookShareButton>
                      <TwitterShareButton
                        title={ele?.guide_title}
                        url={`${currentURL + "guide_details/" + ele?.slug}`}
                      >
                        <img src={twitter} className="social-media" />
                      </TwitterShareButton>
                      <LinkedinShareButton
                        title={ele?.guide_title}
                        url={`${currentURL + "guide_details/" + ele?.slug}`}
                      >
                        <img src={linkedin} className="social-media" />
                      </LinkedinShareButton>

                      {/* <img src={instagram} className="social-media" /> */}
                    </div>
                  </div>
                  <div className="date-container">
                    {moment(ele?.guide_date)?.format("DD MMM YYYY")}
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
      </Container>
      <div className="pagination-container">
        <Container>
          <Row>
            <Col md={6} lg={6} className="result-number-section">
              {pageStatus}
            </Col>
            {pageCount > 1 ? (
              <Col md={6} lg={6} className="pagination-section">
                <ReactPaginate
                  ref={pagination}
                  pageCount={pageCount}
                  pageRangeDisplayed={4}
                  forcePage={page}
                  marginPagesDisplayed={1}
                  onPageChange={(e) => handlePageChange(e)}
                  containerClassName="pagination"
                  activeClassName="active"
                  pageLinkClassName="page-link"
                  breakLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  pageClassName="page-item"
                  breakClassName="page-item"
                  nextClassName="page-item"
                  previousClassName="page-item"
                  previousLabel={<i className="bi bi-chevron-left"></i>}
                  nextLabel={<i className="bi bi-chevron-right"></i>}
                />
              </Col>
            ) : (
              ""
            )}
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Guide;
