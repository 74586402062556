import React, { useEffect, useRef, useState } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { getTeam } from "../redux/actionCreator";
import {
  general_details,
  loading_spinner,
  team_lists,
  team_pageCount,
} from "../redux/commonReducer";
import { webURL } from "../constants/configuration";
import LoadingSpinner from "../components/LoadingSpinner";
import ReactPaginate from "react-paginate";
function Teams() {
  const dispatch = useDispatch();
  const teamLists = useSelector(team_lists);
  const loading = useSelector(loading_spinner);
  const data = useSelector(general_details);
  const pageCount = useSelector(team_pageCount);

  const [page, setPage] = useState(0);
  const pagination = useRef();
  useEffect(() => {
    let formData = new FormData();
    formData.append("perPage", 6);
    formData.append("page", 0);
    dispatch(getTeam(formData,()=>{
      scrollToTop()
    }));
  }, []);

  const handlePageChange = (e) => {
    // console.log('page',e.selected)
    setPage(e.selected);
    if (e.selected >= 0) {
      let formData = new FormData();
      formData.append("perPage", 6);
      formData.append("page", e.selected);
      dispatch(getTeam(formData,()=>{
        scrollToTop()
      }));
    }
  };

  const scrollToTop = () => {
    // window.scrollTo({top: 200});
    if(!window.matchMedia("(min-width: 768px)")?.matches){
      window.scrollTo({top: 400});
      const divElement = document.getElementById("propertySection");
      divElement?.scrollIntoView({ behavior: "smooth" });
    }else {
      window.scrollTo({top: 0});
    }
  }

  return (
    <section className="prima__teams">
      <div className="container">
        <div className="inner__banner fadeInUp delay1">
          <h1>{data?.team_title}</h1>
          <p>{data?.team_tagline}</p>
        </div>
        <div className="row" id="propertySection">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <>
              {teamLists &&
                [...teamLists]
                  ?.sort((a, b) => {
                    if (a.sort_order > b.sort_order) return 1;
                    else if (a.sort_order < b.sort_order) return -1;
                    else return 0;
                  })
                  .map((ele, i) => {
                    return (
                      <div className="col-md-6" key={i}>
                        <div className="team__grid fadeInUp delay1">
                          <div className="row">
                            <div className="col-md-6 pr-0 col-tab-12">
                              <div className="team__img">
                                <img
                                  src={webURL + "team/" + ele?.team_image}
                                  alt=""
                                />
                              </div>
                            </div>
                            <div className="col-md-6 pl-0 col-tab-12">
                              <div className="member__detail">
                                <h2>{ele?.name}</h2>
                                <h4>{ele?.designation}</h4>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: ele?.description,
                                  }}
                                ></p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </>
          )}

          {pageCount > 1 ? (
            <div className="col-md-12">
              <div className="team__pagination">
                <ReactPaginate
                  ref={pagination}
                  pageCount={pageCount}
                  pageRangeDisplayed={4}
                  forcePage={page}
                  marginPagesDisplayed={1}
                  onPageChange={(e) => handlePageChange(e)}
                  containerClassName="pagination"
                  activeClassName="active"
                  pageLinkClassName="page-link"
                  breakLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  pageClassName="page-item"
                  breakClassName="page-item"
                  nextClassName="page-item"
                  previousClassName="page-item"
                  previousLabel={<i className="bi bi-chevron-left"></i>}
                  nextLabel={<i className="bi bi-chevron-right"></i>}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      
    </section>
  );
}

export default Teams;
