import React from "react";
import "./GuideDetails.css";
import upArrow from "../assets/image/ios-arrow-up.svg";
import rightArrow from "../assets/image/right_arrow.svg";
import facebook from "../assets/image/fbblack.svg";
import twitter from "../assets/image/twitterblack.svg";
import instagram from "../assets/image/instagramblack.svg";
import linkedin from "../assets/image/linkedinblack.svg";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { getGuideDetails } from "../redux/actionCreator";
import { useEffect } from "react";
import { guide_details, guide_path } from "../redux/commonReducer";
import moment from "moment";
import { currentURL, webURL } from "../constants/configuration";
import { Link } from "react-router-dom";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import MetaHead from "./metaHead";

const GuideDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const guideDetail = useSelector(guide_details);
  const guidePath = useSelector(guide_path);

  useEffect(() => {
    dispatch(getGuideDetails(id));
  }, []);

  return (
    <div>
      <MetaHead
        title={guideDetail?.meta_title}
        keyword={guideDetail?.meta_keyword}
        description={guideDetail?.meta_description}
      />
      <div className="guide-details-main" data-aos="fade-up">
        <img
          src={webURL + guidePath + "/" + guideDetail?.guide_image}
          width={"100%"}
          className="guide-details-image"
        />
        <div className="guide-details-title-container">
          <div className="guide-details-date-tag">
            {/* {moment(guideDetail?.createdAt).format('Do MMMM YYYY')} */}
            {moment(guideDetail?.guide_date)?.format("DD MMM YYYY")}
          </div>
          <h2 className="guide-details-title">{guideDetail?.guide_title}</h2>
        </div>
      </div>
      {/* <div className='guide-details-breadcrumbs'>
                
            </div> */}
      <div className="guide-details-section guide__details" data-aos="fade-up">
        <div className="guide-breadcrumbs guide-details-breadcrumbs">
          <img src={rightArrow} width={20} className="breadcrumbs-back" />
          <span
            className="breadcrumbs-link pointer"
            onClick={() => navigate(-1)}
          >
            Back
          </span>
          <div className="right-border"></div>
          <Link to="/">
            <span className="breadcrumbs-link pointer">Home</span>
          </Link>
          <img src={upArrow} className="guide-breadcrumbs-icon" />
          <Link to="/guide">
            <span className="breadcrumbs-link pointer">Guide</span>
          </Link>
        </div>
        {/* <h2 className='guide-details-details-title'>{guideDetail?.guide_title}</h2> */}
        <p
          className="guide-details-paragraph"
          dangerouslySetInnerHTML={{ __html: guideDetail?.description }}
        ></p>
        {guideDetail?.image1 && (
          <>
            <img
              className="tag-image"
              src={webURL + guidePath + "/" + guideDetail?.image1}
              width={"100%"}
              data-aos="fade-up"
            />
            <div className="guide-details-image-name">
              <i>{guideDetail?.image_tag}</i>
            </div>
          </>
        )}

        <div className="guide-details-share-section">
          <span className="share-title">Share</span>
          <div className="social-media-links guide-social-media-links">
            <FacebookShareButton
              title={guideDetail?.guide_title}
              url={currentURL + "guide_details/" + guideDetail?.slug}
            >
              <img src={facebook} className="social-media" />
            </FacebookShareButton>
            <TwitterShareButton
              title={guideDetail?.guide_title}
              url={currentURL + "guide_details/" + guideDetail?.slug}
            >
              <img src={twitter} className="social-media" />
            </TwitterShareButton>
            <LinkedinShareButton
              title={guideDetail?.guide_title}
              url={`${currentURL + "guide_details/" + guideDetail?.slug}`}
              // url={"https://prima-dev.shtdevops.xyz/guide_details/Test-Guide-2"}
            >
              <img src={linkedin} className="social-media" />
            </LinkedinShareButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuideDetails;
