import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import Modal from "react-bootstrap/Modal";
import SimilarProperties from "../components/SimilarProperties";
import { NumericFormat } from "react-number-format";
import {
  addWishList,
  deleteWishList,
  getPropertyDetails,
  getSimilarProperties,
  registerInterest,
} from "../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import {
  banner_path,
  brochure_path,
  contact_details,
  detail_favourite,
  floorplan_path,
  gallery_path,
  general_details,
  property_details,
  property_is_favorite,
  register_interest_loading,
  updateRedux,
  user_logged,
} from "../redux/commonReducer";
import { mapAPI, webURL } from "../constants/configuration";

import "./propertyDetails.scss";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import lgVideo from "lightgallery/plugins/video";
import Fancybox from "../components/Fancybox";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import MetaHead from "./metaHead";

function PropertyDetails() {
  const token = localStorage.getItem("token");
  const [more, setMore] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const myRef = useRef(null);

  const propertyItem = useSelector(property_details);
  const galleryPath = useSelector(gallery_path);
  const bannerPath = useSelector(banner_path);
  const floorplanPath = useSelector(floorplan_path);
  const brochurePath = useSelector(brochure_path);
  const data = useSelector(general_details);
  const contactDetails = useSelector(contact_details);
  const is_favourite = useSelector(property_is_favorite);
  const userLogged = useSelector(user_logged);
  const loading = useSelector(register_interest_loading);

  const [activeMarker, setActiveMarker] = useState(null);
  const [show, setShow] = useState(false);
  const [needsMoreButton, setNeedsMoreButton] = useState(false);
  const [propertyNo, setPropertyNo] = useState("");
  const [message, setMessage] = useState("");
  const [messageErr, setMessageErr] = useState("");

  // useEffect(() => {
  //   dispatch(getPropertyDetails(id));
  // }, [token]);

  const redirectTo404Page = () => {
    navigate("/404Page")
  }

  useEffect(() => {
    dispatch(getPropertyDetails(id, () => redirectTo404Page()));
    dispatch(getSimilarProperties(id));
  }, [id, userLogged, token]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setPropertyNo(propertyItem?.property_no);
    setMessage(
      `Hi, I'd like to know more about the property with Ref No: ${propertyItem?.property_no}`
    );
  }, [propertyItem]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      message: `Hi, I'd like to know more about the property with Ref No: ${propertyNo}`,
    },
  });

  const videoArray = [];
  const imageArray = [];
  propertyItem?.gallery?.map((ele) => {
    if (ele.type == "video") videoArray.push(ele);
    else imageArray.push(ele);
  });

  let allImages = imageArray.concat(videoArray);
  allImages.sort((a, b) =>
    Number(a.position) > Number(b.position)
      ? 1
      : Number(b.position) > Number(a.position)
      ? -1
      : 0
  );
  // console.log(imageArray, allImages)

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: mapAPI,
  });

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const readMore = () => {
    setMore(!more);
  };

  useEffect(() => {
    if (myRef.current.clientHeight) {
      setTimeout(() => {
        setNeedsMoreButton(myRef?.current?.clientHeight);
      }, 1000);
    }
  }, []);

  const onSubmit = (data) => {
    console.log(data, "data");
    if (message?.trim()?.length == 0) {
      setMessageErr("This field is required");
    } else {
      let value = {
        first_name: data?.first_name,
        last_name: data?.last_name,
        email: data?.email,
        phone: data?.phone,
        message: message,
        property_id: propertyItem?._id,
      };
      dispatch(
        registerInterest(value, () => {
          reset();
          setShow(false);
          setMessage("");
          setMessageErr("");
        })
      );
    }
  };

  const removeWishlistHandler = (fav_id) => {
    if (token != null) {
      let data = {
        property_id: fav_id,
      };
      dispatch(
        deleteWishList(data, () => {
          dispatch(getPropertyDetails(id));
        })
      );
    } else {
      // toast.error("Please Login", {
      //   position: "bottom-center",
      //   autoClose: 3000,
      // });
      dispatch(updateRedux({ key: "login_modal_show", value: true }));
    }
  };
  const addWishlistHandler = (fav_id) => {
    if (token != null) {
      let data = {
        property_id: fav_id,
      };
      dispatch(
        addWishList(data, () => {
          dispatch(getPropertyDetails(id));
        })
      );
    } else {
      // toast.error("Please Login", {
      //   position: "bottom-center",
      //   autoClose: 3000,
      // });
      dispatch(updateRedux({ key: "login_modal_show", value: true }));
    }
  };

  console.log("imageArray", imageArray);
  return (
    <>
      <MetaHead
        title={propertyItem?.meta_title}
        keyword={propertyItem?.meta_keyword}
        description={propertyItem?.meta_description}
      />
      <Modal show={show} centered onHide={() => setShow(false)}>
        <Modal.Body>
          <div className="prima__login register__interest">
            <i className="bi bi-x" onClick={() => setShow(false)}></i>
            <div className="inner__banner">
              <h1 className="register_interest_text">REGISTER INTEREST</h1>
            </div>
            <div className="forms">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <input
                      type="text"
                      autoComplete="on"
                      className="form-control"
                      placeholder="First Name*"
                      {...register("first_name", {
                        required: true,
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "First name should have only alphabets",
                        },
                      })}
                      aria-invalid={errors.first_name ? "true" : "false"}
                    />
                    {errors.first_name && (
                      <span className="error">
                        {errors.first_name?.message != ""
                          ? errors.first_name?.message
                          : "This field is required"}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <input
                      type="text"
                      autoComplete="on"
                      className="form-control"
                      placeholder="Last Name*"
                      {...register("last_name", {
                        required: true,
                        pattern: {
                          value: /^[a-zA-Z ]*$/,
                          message: "Last name should have only alphabets",
                        },
                      })}
                      aria-invalid={errors.last_name ? "true" : "false"}
                    />
                    {errors.last_name && (
                      <span className="error">
                        {errors.last_name?.message != ""
                          ? errors.last_name?.message
                          : "This field is required"}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <input
                      type="text"
                      autoComplete="on"
                      className="form-control"
                      placeholder="Email*"
                      {...register("email", {
                        required: true,
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
                          message: "Entered value does not match email format",
                        },
                      })}
                      aria-invalid={errors.email ? "true" : "false"}
                    />
                    {errors.email && (
                      <span className="error">
                        {errors.email?.message != ""
                          ? errors.email?.message
                          : "This field is required"}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <input
                      type="text"
                      autoComplete="on"
                      className="form-control"
                      placeholder="Phone*"
                      {...register("phone", {
                        required: true,
                        pattern: {
                          value: /^[0-9]{9,13}$/,
                          message:
                            "Invalid Phone Number (Phone field should have numbers only)",
                        },
                      })}
                      aria-invalid={errors.phone ? "true" : "false"}
                    />
                    {errors.phone && (
                      <span className="error">
                        {errors.phone?.message != ""
                          ? errors.phone?.message
                          : "This field is required"}
                      </span>
                    )}
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="">Comment</label>
                    {/* <textarea
                      rows="3"
                      className="form-control"
                      value={data?.message}
                      {...register("message", {
                        required: true,
                      })}
                      aria-invalid={errors.message ? "true" : "false"}
                    ></textarea>
                    {errors.message && (
                      <span className="error">
                        {errors.message?.message != ""
                          ? errors.message?.message
                          : "This field is required"}
                      </span>
                    )} */}
                    <textarea
                      rows="3"
                      className="form-control"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                        setMessageErr("");
                      }}
                    ></textarea>
                    {messageErr && (
                      <span className="error">
                        {messageErr != ""
                          ? messageErr
                          : "This field is required"}
                      </span>
                    )}
                  </div>
                </div>
                <div className="btns">
                  <button className="btn btn-submit" disabled={loading}>
                    Submit{" "}
                    {loading ? (
                      <i className="spinner-border spinnerStyle" style={{color: "black"}}></i>
                    ) : (
                      <i className="bi bi-arrow-right"></i>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* webURL + "property_banner_images/" + propertyItem?.property_banner_image */}
      <section
        className="prima__property_details"
        // style={{
        //   backgroundImage: `url("${
        //     webURL + bannerPath + "/" + propertyItem?.property_banner_image
        //   }")`,
        // }}
      >
        <div>
          <img
            src={`${webURL}${bannerPath}/${propertyItem?.property_banner_image}`}
            alt=""
            className="property-details-banner-image"
          ></img>
        </div>
        <div className="container">
          <div className="property__banner">
            <ul className={imageArray?.length == 0 ? "d-none" : ""}>
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                }}
              >
                {imageArray?.length != 0 ? (
                  // <li data-fancybox="gallery">
                  <a
                    data-fancybox="gallery"
                    href={
                      webURL + galleryPath + "/" + imageArray[0]?.gallery_image
                    }
                  >
                    All Photos
                  </a>
                ) : (
                  // </li>
                  ""
                )}

                <div className="banner-iamges">
                  {imageArray.slice(1)?.map((ele, i) => {
                    return (
                      <a
                        key={i}
                        data-fancybox="gallery"
                        href={webURL + galleryPath + "/" + ele?.gallery_image}
                      >
                        <img
                          src={webURL + galleryPath + "/" + ele?.gallery_image}
                        />
                      </a>
                    );
                  })}
                </div>
              </Fancybox>
              <Fancybox
                options={{
                  Carousel: {
                    infinite: false,
                  },
                }}
              >
                {videoArray?.length != 0 ? (
                  <li className="first-child videos-text" data-fancybox="gallery">
                    <a
                      data-fancybox="gallery"
                      href={videoArray[0]?.gallery_image}
                    >
                      Videos
                    </a>
                  </li>
                ) : (
                  ""
                )}

                <div className="banner-iamges">
                  {videoArray?.slice(1)?.map((ele, i) => {
                    return (
                      <a
                        key={i}
                        data-fancybox="gallery"
                        href={ele?.gallery_image}
                      >
                        <img
                          className="rounded video-3d"
                          src={`https://img.youtube.com/vi/${ele?.gallery_videoId}/maxresdefault.jpg`}
                        />
                      </a>
                    );
                  })}
                </div>
              </Fancybox>
            </ul>
          </div>
        </div>
      </section>
      <section className="property__details">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div className="left__contents">
                <div className="top__section">
                  <div className="back pointer" onClick={() => navigate(-1)}>
                    <p>
                      <i className="bi bi-arrow-left"></i> Back
                    </p>
                  </div>
                  <div className="breadcrumbs">
                    <ul>
                      <li>
                        <Link to="/">
                          Home <i className="bi bi-chevron-right"></i>
                        </Link>
                      </li>
                      <li>
                        <Link to="/all-properties">
                          All Properties <i className="bi bi-chevron-right"></i>
                        </Link>
                      </li>
                      <li>{propertyItem?.location[0]?.location_name}</li>
                    </ul>
                  </div>
                </div>
                <div className="location">
                  <img src="/assets/img/icons/location-yellow.svg" alt="" />
                  <p>
                    {propertyItem?.building}
                    {propertyItem?.city ? "," : ""} {propertyItem?.city}
                    {propertyItem?.community ? "," : ""}{" "}
                    {propertyItem?.community}
                  </p>
                </div>
                <div className="title">
                  <h1>{propertyItem?.property_name}</h1>
                  <ul>
                    <li>{propertyItem?.residential}</li>
                    <li>
                      {propertyItem?.residential == "commercial" ? (
                        <>{propertyItem?.pantry} Pantry</>
                      ) : propertyItem?.bedroom == "0" ||
                        propertyItem?.bedroom == "Studio" ? (
                        "Studio"
                      ) : (
                        <> {propertyItem?.bedroom} Bedroom</>
                      )}
                    </li>
                    <li>{propertyItem?.bathroom} Bathroom</li>
                    <li className="lowercase">{propertyItem?.sqft} sq.ft.</li>
                  </ul>
                  <h6>
                    <span>ORN: {data?.orn}</span> |{" "}
                    <span>License No : {data?.license}</span> |
                    <span>Permit No : {propertyItem?.permit}</span>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="price__section">
                <p>
                  <span>{propertyItem?.buy}</span>{" "}
                  {propertyItem?.is_exclusive == "on" ? (
                    <>
                      | <span>Exclusive </span>
                    </>
                  ) : (
                    ""
                  )}{" "}
                  {propertyItem?.buy != "rent" && (
                    <>
                      | <span>Ready</span>
                    </>
                  )}
                </p>
                {propertyItem?.askprice == 0 ? (
                  <h2>
                    <NumericFormat
                      value={propertyItem?.price}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"AED "}
                    />
                  </h2>
                ) : (
                  <h2 className="ask__price">ask for price</h2>
                )}
                <h5>Ref No : {propertyItem?.property_no}</h5>
                <button
                  className="btn btn-register"
                  onClick={() => setShow(true)}
                >
                  Register Interest
                </button>
                <ul>
                  <li>
                    <a href={`tel:971${contactDetails?.telephone}`}>
                      <img src="/assets/img/icons/phone.svg" alt="" />
                      <p>Phone</p>
                    </a>
                  </li>
                  <li>
                    <a
                      href={`https://wa.me/971${contactDetails?.mobile}?text=Dear PRIMA LUXURY, I am interested in one of your properties - ${propertyItem?.property_name} with the Ref No: ${propertyItem?.property_no} and I would appreciate if you can get back to me as soon as possible.`}
                      target="_blank"
                    >
                      <img src="/assets/img/icons/whatsapp.svg" alt="" />
                      <p>Whatsapp</p>
                    </a>
                  </li>
                  <li>
                    <a href={`mailto:${contactDetails?.email}`}>
                      <img
                        src="/assets/img/icons/email.svg"
                        style={{ height: "19px" }}
                        alt=""
                      />
                      <p>Email</p>
                    </a>
                  </li>

                  {is_favourite ? (
                    <li
                      className="pointer"
                      onClick={() => removeWishlistHandler(propertyItem._id)}
                    >
                      <img src="/assets/img/icons/whishlist-fill.svg" alt="" />
                      <p>Favorite</p>
                    </li>
                  ) : (
                    <li
                      className="pointer"
                      onClick={() => addWishlistHandler(propertyItem._id)}
                    >
                      <img src="/assets/img/icons/whishlist.svg" alt="" />
                      <p>Favorite</p>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="property__secription">
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              <div
                ref={myRef}
                className={more ? "description" : "description height-auto"}
              >
                <h2>DESCRIPTION</h2>
                <p
                  dangerouslySetInnerHTML={{
                    __html: propertyItem?.description,
                  }}
                ></p>
              </div>
              {needsMoreButton == 390 && (
                <button className="btn btn-primary" onClick={readMore}>
                  {more ? "READ MORE" : "SHOW LESS"}{" "}
                  {more ? (
                    <i className="bi bi-arrow-down"></i>
                  ) : (
                    <i className="bi bi-arrow-up"></i>
                  )}
                </button>
              )}
              {!propertyItem?.features.length == 0 && (
                <div className="features">
                  <h2>FEATURES</h2>
                  <div className="d-flex">
                    <ul>
                      {propertyItem?.features?.map((ele, i) => {
                        return (
                          <li key={i}>
                            <i className="bi bi-check-circle-fill"></i>{" "}
                            {ele?.feature_name}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              )}

              <div className="more__images">
                <Fancybox
                  options={{
                    Carousel: {
                      infinite: false,
                    },
                  }}
                >
                  {propertyItem?.gallery?.length > 1 ? (
                    <a
                      data-fancybox="gallery"
                      href={
                        webURL +
                        galleryPath +
                        "/" +
                        propertyItem?.gallery[0]?.gallery_image
                      }
                    >
                      {" "}
                      <button className="btn btn-view-all">View All</button>
                    </a>
                  ) : (
                    ""
                  )}

                  <div className="banner-iamges d-none">
                    {allImages?.slice(1)?.map((ele, i) => {
                      return (
                        <>
                          {ele?.type == "image" ? (
                            <a
                              key={i}
                              data-fancybox="gallery"
                              href={
                                webURL + galleryPath + "/" + ele?.gallery_image
                              }
                            >
                              <img
                                src={
                                  webURL +
                                  galleryPath +
                                  "/" +
                                  ele?.gallery_image
                                }
                              />
                            </a>
                          ) : (
                            <a
                              key={i}
                              data-fancybox="gallery"
                              href={ele?.gallery_image}
                            >
                              <img
                                className="rounded video-3d"
                                src={`https://img.youtube.com/vi/${ele?.gallery_videoId}/maxresdefault.jpg`}
                              />
                            </a>
                          )}
                        </>
                      );
                    })}
                  </div>
                </Fancybox>
                <Fancybox
                  options={{
                    Carousel: {
                      infinite: false,
                    },
                  }}
                >
                  <div className="all-banner-iamges">
                    <div className="row">
                      {/* {
                        propertyItem?.video?.slice(0, 1)?.map((ele) => {
                          return (
                            <div className='col-md-12'>
                              <div className="image__grid video">
                                <a
                                  data-fancybox="gallery"
                                  href={ele?.gallery_image}
                                >
                                  <img
                                    className="video-3d"
                                    src={webURL + "property_gallery/" + propertyItem?.gallery[2]?.gallery_image}
                                  />
                                </a>
                              </div>
                            </div>
                          )
                        })
                      } */}
                      {propertyItem?.gallery?.map((ele, i) => {
                        if (i > 3) {
                          return "";
                        }
                        if (i == 0) {
                          if (ele?.type == "video") {
                            return (
                              <div className="col-md-12">
                                <div className="image__grid video-icon">
                                  <a
                                    data-fancybox="gallery"
                                    href={ele?.gallery_image}
                                  >
                                    {/* <iframe width="100%" height={400} src={ele?.gallery_image} frameborder="0" allowfullscreen></iframe> */}
                                    <img
                                      className="video-3d"
                                      src={`https://img.youtube.com/vi/${ele?.gallery_videoId}/maxresdefault.jpg`}
                                    />
                                  </a>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className="col-md-12">
                                <div className="image__grid">
                                  <a
                                    data-fancybox="gallery"
                                    href={
                                      webURL +
                                      galleryPath +
                                      "/" +
                                      ele?.gallery_image
                                    }
                                  >
                                    <img
                                      className="video-3d"
                                      src={
                                        webURL +
                                        galleryPath +
                                        "/" +
                                        ele?.gallery_image
                                      }
                                    />
                                  </a>
                                </div>
                              </div>
                            );
                          }
                        } else {
                          if (ele?.type == "video") {
                            return (
                              <div className="col-md-4">
                                <div className="image__grid video sm-video position-relative">
                                  <a
                                    data-fancybox="gallery"
                                    href={
                                      webURL +
                                      galleryPath +
                                      "/" +
                                      ele?.gallery_image
                                    }
                                  >
                                    {/* <iframe width="100%"  src={ele?.gallery_image} frameborder="0" allowfullscreen></iframe> */}
                                    <img
                                      className="video-3d video-small"
                                      src={`https://img.youtube.com/vi/${ele?.gallery_videoId}/maxresdefault.jpg`}
                                    />
                                  </a>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className="col-md-4">
                                <div className="image__grid">
                                  <a
                                    data-fancybox="gallery"
                                    href={
                                      webURL +
                                      galleryPath +
                                      "/" +
                                      ele?.gallery_image
                                    }
                                  >
                                    <img
                                      src={
                                        webURL +
                                        galleryPath +
                                        "/" +
                                        ele?.gallery_image
                                      }
                                    />
                                  </a>
                                </div>
                              </div>
                            );
                          }
                        }
                      })}

                      {/* <div className='col-md-12'>
                        <div className="image__grid video">
                          <a
                            data-fancybox="gallery"
                            href={webURL + "property_gallery/" + propertyItem?.gallery[0]?.gallery_image}
                          >

                            <iframe width="100" height="315" src="https://www.youtube.com/embed/7TF00hJI78Y" frameborder="0" allowfullscreen></iframe>
                            <img
                              className="video-3d"
                              src={webURL + "property_gallery/" + propertyItem?.gallery[0]?.gallery_image}
                            />
                          </a>
                        </div>
                      </div> */}
                      {/* 
                      {
                        propertyItem?.gallery?.slice(0, 3)?.map((ele) => {
                          return (
                            <div className='col-md-4'>
                              <div className="image__grid">
                                <a data-fancybox="gallery" href={webURL + "property_gallery/" + ele?.gallery_image}>
                                  <img src={webURL + "property_gallery/" + ele?.gallery_image} />
                                </a>
                              </div>
                            </div>
                          )
                        })
                      } */}
                    </div>
                  </div>
                </Fancybox>
              </div>

              {propertyItem?.floor_plan || propertyItem?.brochure ? (
                <div className="extras">
                  <h2>EXTRAS</h2>
                  <div className="extras__grids">
                    {propertyItem?.floor_plan ? (
                      <div className="extra__grid">
                        <Fancybox
                          options={{
                            Carousel: {
                              infinite: false,
                            },
                          }}
                        >
                          <a
                            data-fancybox="gallery"
                            data-type="pdf"
                            href={
                              webURL +
                              floorplanPath +
                              "/" +
                              propertyItem?.floor_plan
                            }
                          >
                            <img
                              src="/assets/img/icons/floor-plan.svg"
                              alt=""
                            />
                          </a>
                        </Fancybox>
                        <p>Floor Plan</p>
                      </div>
                    ) : (
                      ""
                    )}
                    {propertyItem?.brochure ? (
                      <div className="extra__grid">
                        <Fancybox
                          options={{
                            Carousel: {
                              infinite: false,
                            },
                          }}
                        >
                          <a
                            data-fancybox="gallery"
                            href={
                              webURL +
                              brochurePath +
                              "/" +
                              propertyItem?.brochure
                            }
                          >
                            <img src="/assets/img/icons/brochure.svg" alt="" />
                          </a>
                        </Fancybox>

                        <p>Brochure</p>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}

              <hr />
              <div className="location-map">
                <h2>Location</h2>
                {isLoaded ? (
                  <GoogleMap
                    center={{
                      lat: Number(Number(propertyItem?.latitude)),
                      lng: Number(Number(propertyItem?.longitude)),
                    }}
                    zoom={10}
                    onClick={() => setActiveMarker(null)}
                    mapContainerStyle={{ width: "100%", height: "450px" }}
                  >
                    <MarkerF
                      key={id}
                      position={{
                        lat: Number(Number(propertyItem?.latitude)),
                        lng: Number(Number(propertyItem?.longitude)),
                      }}
                      onClick={() => handleActiveMarker(id)}
                      icon={{
                        url: "/assets/img/icons/marker.svg",
                      }}
                    ></MarkerF>
                  </GoogleMap>
                ) : null}
              </div>
            </div>
            <div className="col-md-4">
              <div className="sticky__price">
                <div className="price__card">
                  <div className="p-30 pb-2">
                    <div className="location">
                      <img src="/assets/img/icons/location-yellow.svg" alt="" />
                      <p>
                        {propertyItem?.building}
                        {propertyItem?.city ? "," : ""} {propertyItem?.city}
                        {propertyItem?.community ? "," : ""}{" "}
                        {propertyItem?.community}
                      </p>
                    </div>
                    <div className="title">
                      <h2>{propertyItem?.property_name}</h2>
                      <ul>
                        <li>{propertyItem?.buy}</li>
                        {propertyItem?.is_exclusive == "on" ? (
                          <li>Exclusive</li>
                        ) : null}
                        {propertyItem?.buy != "rent" && <li>Ready</li>}
                      </ul>
                    </div>
                  </div>
                  <div className="property-price">
                    <ul>
                      <li>{propertyItem?.residential}</li>
                      <li>
                        {propertyItem?.residential == "commercial" ? (
                          <>{propertyItem?.pantry} Pantry</>
                        ) : propertyItem?.bedroom == "0" ||
                          propertyItem?.bedroom == "Studio" ? (
                          "Studio"
                        ) : (
                          <>{propertyItem?.bedroom} Bedroom</>
                        )}
                      </li>
                      <li>{propertyItem?.bathroom} Bathroom</li>
                      <li className="lowercase">{propertyItem?.sqft} sq.ft.</li>
                    </ul>
                    {propertyItem?.askprice == "0" ? (
                      <h2>
                        <NumericFormat
                          value={propertyItem?.price}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"AED "}
                        />
                      </h2>
                    ) : (
                      <h2 className="ask__price">ask for price</h2>
                    )}
                  </div>
                  <div className="p-30 pt-0">
                    <div className="license">
                      <p>
                        Ref No : {propertyItem?.property_no} <span>|</span> ORN:{" "}
                        {data?.orn}
                      </p>
                      <p>
                        License No : {data?.license}{" "}
                        <span style={{ color: "#F8B133" }}>|</span> Permit No :{" "}
                        {propertyItem?.permit}
                      </p>
                    </div>
                    <button
                      className="btn btn-primary"
                      onClick={() => setShow(true)}
                    >
                      Register Interest
                    </button>
                    <div className="share__links">
                      <ul>
                        <li>
                          <a href={`tel:${contactDetails?.telephone}`}>
                            <img src="/assets/img/icons/phone.svg" alt="" />
                            <p>Phone</p>
                          </a>
                        </li>
                        <li>
                          <a
                            href={`https://wa.me/${contactDetails?.mobile}?text=Dear PRIMA LUXURY, I am interested in one of your properties - ${propertyItem?.property_name} with the Ref No: ${propertyItem?.property_no} and I would appreciate if you can get back to me as soon as possible.`}
                            target="_blank"
                          >
                            <img src="/assets/img/icons/whatsapp.svg" alt="" />
                            <p>Whatsapp</p>
                          </a>
                        </li>
                        <li>
                          <a href={`mailto:${contactDetails?.email}`}>
                            <img
                              src="/assets/img/icons/email.svg"
                              style={{ height: "19px" }}
                              alt=""
                            />
                            <p>Email</p>
                          </a>
                        </li>
                        {is_favourite ? (
                          <li
                            className="pointer"
                            onClick={() =>
                              removeWishlistHandler(propertyItem._id)
                            }
                          >
                            <img
                              style={{ height: "19px" }}
                              src="/assets/img/icons/whishlist-fill.svg"
                              alt=""
                            />
                            <p>Favorite</p>
                          </li>
                        ) : (
                          <li
                            className="pointer"
                            onClick={() => addWishlistHandler(propertyItem._id)}
                          >
                            <img src="/assets/img/icons/whishlist.svg" alt="" />
                            <p>Favorite</p>
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="mb-2" />
          </div>
        </div>
      </section>
      <SimilarProperties id={id} />
    </>
  );
}

export default PropertyDetails;
