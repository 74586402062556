import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import closeButton from "../assets/image/close-button.svg";
import "./ModalComponent.css";
import { Col, Container, Row } from "react-bootstrap";
import rightArrow from "../assets/image/right_arrow.svg";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { listProperty } from "../redux/actionCreator";
import { list_property_loading } from "../redux/commonReducer";

const ModalComponent = ({ modalActive, setModalActive }) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
  } = useForm();

  const dispatch = useDispatch();

  const loading = useSelector(list_property_loading);

  const [serviceType, setServiceType] = useState("sale");

  const handleClose = () => setModalActive(false);

  const onSubmit = (data) => {
    console.log(data, "data");
    let value = {
      first_name: data?.first_name,
      last_name: data?.last_name,
      email: data?.email,
      phone: data?.phone,
      message: data?.message,
      buy: serviceType,
    };
    dispatch(
      listProperty(value, () => {
        reset();
        setServiceType("sale");
        setModalActive(false);
      })
    );
  };

  return (
    <>
      <Modal
        show={modalActive}
        onHide={handleClose}
        dialogClassName="my-modal"
        centered
      >
        <Modal.Header closeButton className="d-none"></Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column align-items-center justify-content-center container-modal">
            <div className="d-flex align-items-end justify-content-end mb-3 w-100 cursor-pointer">
              <img
                src={closeButton}
                width={18}
                className="close-button"
                onClick={() => setModalActive(false)}
              />
            </div>
            <div className="red">
              <div className="d-flex flex-column align-items-center justify-content-center mb-3">
                <h2 className="modal-title">LIST YOUR PROPERTY</h2>
                <div className="modal-title-underline"></div>
              </div>
              <Container>
                <div className="service-type-container d-flex mb-3 mt-4 ">
                  <button
                    className={`${
                      serviceType == "sale"
                        ? "btn btn-sm-sell active"
                        : "btn btn-sm-sell"
                    } mr-3`}
                    onClick={() => setServiceType("sale")}
                  >
                    SELL WITH US
                  </button>
                  <button
                    className={`${
                      serviceType == "rent"
                        ? "btn btn-sm-sell active"
                        : "btn btn-sm-sell"
                    }`}
                    onClick={() => setServiceType("rent")}
                  >
                    RENT WITH US
                  </button>
                </div>
              </Container>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="service-type-container d-flex mb-3 mt-4 ">
                  <Container>
                    <Row>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <input
                          className="modal-input"
                          placeholder="First Name*"
                          {...register("first_name", {
                            required: true,
                            pattern: {
                              value: /^[a-zA-Z ]*$/,
                              message: "First name should have only alphabets",
                            },
                          })}
                          aria-invalid={errors.first_name ? "true" : "false"}
                        />
                        {errors.first_name && (
                          <span className="error">
                            {errors.first_name?.message != ""
                              ? errors.first_name?.message
                              : "This field is required"}
                          </span>
                        )}
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <input
                          className="modal-input"
                          placeholder="Last Name*"
                          {...register("last_name", {
                            required: true,
                            pattern: {
                              value: /^[a-zA-Z ]*$/,
                              message: "Last name should have only alphabets",
                            },
                          })}
                          aria-invalid={errors.last_name ? "true" : "false"}
                        />
                        {errors.last_name && (
                          <span className="error">
                            {errors.last_name?.message != ""
                              ? errors.last_name?.message
                              : "This field is required"}
                          </span>
                        )}
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <input
                          className="modal-input"
                          placeholder="Email*"
                          {...register("email", {
                            required: true,
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,3}))$/,
                              message:
                                "Entered value does not match email format",
                            },
                          })}
                          aria-invalid={errors.email ? "true" : "false"}
                        />
                        {errors.email && (
                          <span className="error">
                            {errors.email?.message != ""
                              ? errors.email?.message
                              : "This field is required"}
                          </span>
                        )}
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <input
                          className="modal-input"
                          placeholder="Phone*"
                          {...register("phone", {
                            required: true,
                            pattern: {
                              value: /^[0-9]{9,13}$/,
                              message:
                                "Invalid Phone Number (Phone field should have numbers only)",
                            },
                          })}
                          aria-invalid={errors.phone ? "true" : "false"}
                        />
                        {errors.phone && (
                          <span className="error">
                            {errors.phone?.message != ""
                              ? errors.phone?.message
                              : "This field is required"}
                          </span>
                        )}
                      </Col>
                      <Col xs={12} md={12}>
                        <label className="comment-label">Comment*</label>
                        <br />
                        <textarea
                          placeholder="Hi, I'd like to sell / rent my property with Prima Luxury Real Estate."
                          className="modal-input-comment"
                          {...register("message", {
                            required: true,
                          })}
                          aria-invalid={errors.message ? "true" : "false"}
                        />
                        {errors.message && (
                          <span className="error">
                            {errors.message?.message != ""
                              ? errors.message?.message
                              : "This field is required"}
                          </span>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </div>
                <Container>
                  <button
                    className="submit-button bg-transparent"
                    disabled={loading}
                  >
                    SUBMIT
                    {/* <img src={rightArrow} width={15} /> */}
                    {loading ? (
                      <i className="spinner-border spinnerStyle"></i>
                    ) : (
                      <i className="bi bi-arrow-right"></i>
                    )}
                  </button>
                </Container>
              </form>
            </div>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ModalComponent;
