import React from 'react'
import { Helmet } from 'react-helmet'

const MetaHead = ({ title, keyword, description }) => {
  return (
    <Helmet>
      <title> {'Prima Luxury Real Estate Dubai |' +title}</title>
      <meta name="keywords" content={keyword} />
      <meta name="description" content={description} />
    </Helmet>
  )
}

export default MetaHead