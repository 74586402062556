import axios from 'axios';
import { toast } from 'react-toastify';
import { API_BASE_URL } from "../constants/configuration";
import { updateRedux } from './commonReducer';
import axiosInstance from '../utils/axiosInterceptor';
import axiosFormInstance from '../utils/axiosFormInterceptor'

const formHeader = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  },
}

const header = {
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  },
}

export const registerNow = (data) => (dispatch) => {
  dispatch(updateRedux({ key: 'register_state', value: data }))
}

export const getProperties = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: 'loading_spinner', value: true }))
  axiosInstance.post(`${API_BASE_URL}v1/getProperties`, data).then(res => {
    if (res.data.status == true) {
      // console.log('allProperties_count',res.data.pages)
      dispatch(updateRedux({ key: 'loading_spinner', value: false }))
      dispatch(updateRedux({ key: 'allProperties_count', value: res.data.pages }))
      dispatch(updateRedux({ key: 'property_lists', value: res.data.propertList }))
      dispatch(updateRedux({ key: 'propertyListsCount', value: res.data.page_status }))
      if(callback){
        callback()
      }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}


export const getPropertyDetails = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: 'property_is_favorite', value: false }))
  axiosInstance.get(`${API_BASE_URL}v1/getPropertyDetails/${id}`).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'property_details', value: res.data.property_details }))
      dispatch(updateRedux({ key: 'property_is_favorite', value: res.data.is_favourite }))
      dispatch(updateRedux({ key: 'gallery_path', value: res.data.gallery_path }))
      dispatch(updateRedux({ key: 'banner_path', value: res.data.banner_path }))
      dispatch(updateRedux({ key: 'thumbnail_path', value: res.data.thumbnail_path }))
      dispatch(updateRedux({ key: 'floorplan_path', value: res.data.floorplan_path }))
      dispatch(updateRedux({ key: 'brochure_path', value: res.data.brochure_path }))
      dispatch(updateRedux({ key: 'detail_favourite', value: res.data?.is_favourite }))
    } else {
      // toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if(callback){
        callback()
      }
    }
  }).catch((err) => { console.log(err) })
}

export const getGuide = (data, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}v1/getGuide`, data).then(res => {
    // console.log("123",res.data)
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'guide_lists', value: res.data.guideList }))
      dispatch(updateRedux({ key: 'guide_pageStatus', value: res.data.page_status }))
      dispatch(updateRedux({ key: 'guide_pageCount', value: res.data.pages }))
      if(callback){
        callback()
      }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const getGuideDetails = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}v1/getGuideDetails/${id}`).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'guide_details', value: res.data.guide_details?.[0] }))
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const getSimilarProperties = (id) => (dispatch) => {
  console.log(id)
  axiosInstance.get(`${API_BASE_URL}v1/getSimilarProperties/${id}`).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'similar_properties', value: res.data.data.mergeArray }))
    } else {
      // toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}


export const applyFilter = (data) => (dispatch) => {
  dispatch(updateRedux({ key: 'loading_spinner', value: true }))
  axiosInstance.post(`${API_BASE_URL}v1/applyFilter`, data).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'loading_spinner', value: false }))
      dispatch(updateRedux({ key: 'property_lists', value: res.data.data.property }))
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}


export const getPropertyTypes = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}v1/getPropertyTypes`).then(res => {
    if (res.data.status == true) {

      dispatch(updateRedux({ key: 'propertTypeList', value: res.data.propertTypeList }))
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const getFeatures = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}v1/getFeatures`).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'amenities_lists', value: res.data.featureList }))
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const getFeaturesForFilter = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}v1/get-features-for-filter`).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'amenities_lists_for_filter', value: res.data.featureList }))
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const getTeam = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: 'loading_spinner', value: true }))
  axiosFormInstance.post(`${API_BASE_URL}v1/getTeam`, formData).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'team_lists', value: res.data.teamList }))
      dispatch(updateRedux({ key: 'team_pageCount', value: res.data.pages }))
      dispatch(updateRedux({ key: 'loading_spinner', value: false }))
      if(callback){
        callback()
      }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}


export const getCms = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}v1/getCms`).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'contact_details', value: res.data.data.contact_details }))
      dispatch(updateRedux({ key: 'privacy_details', value: res.data.data.privacy_details }))
      dispatch(updateRedux({ key: 'terms_details', value: res.data.data.terms_details }))
      dispatch(updateRedux({ key: 'about_details', value: res.data.data.about_details }))
      dispatch(updateRedux({ key: 'general_details', value: res.data.data.general_details }))
      dispatch(updateRedux({ key: 'about_images', value: res.data.data.aboutImages }))
      dispatch(updateRedux({ key: 'general_path', value: res.data.path }))
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}


export const getCareer = () => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}v1/getCareer`).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'career_lists', value: res.data.careerList }))
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const getCareerDetails = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}v1/getCareerDetails/${id}`).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'career_details', value: res.data.career_details[0] }))
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}


export const getHome = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}v1/getHome`).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'home_loading', value: false }))
      dispatch(updateRedux({ key: 'home_details', value: res.data.home_details }))
      dispatch(updateRedux({ key: 'home_premium', value: res.data.premium_property }))
      dispatch(updateRedux({ key: 'guide_path', value: res.data.guide_path }))
      dispatch(updateRedux({ key: 'gallery_path', value: res.data.property_path }))
      dispatch(updateRedux({ key: 'thumbnail_path', value: res.data.property_thumbnail_path }))
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const registerForm = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: 'register_loading', value: true }))
  axios.post(`${API_BASE_URL}v1/register`, data).then(res => {
    dispatch(updateRedux({ key: 'register_loading', value: false }))
    if (res.data.status == true) {
      localStorage.setItem('token', res.data.accessToken)
      localStorage.setItem('name', res.data.name)
      localStorage.setItem('email', res.data.email)
      localStorage.setItem('phone', res.data.phone)
      localStorage.setItem('user_logged', true)
      dispatch(updateRedux({ key: 'user_logged', value: true }))
      if (callback) {
        callback()
      }
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { 
    dispatch(updateRedux({ key: 'register_loading', value: false }))
    console.log(err) 
  })
}

export const login = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: 'login_loading', value: true }))
  axios.post(`${API_BASE_URL}v1/login`, data).then(res => {
    dispatch(updateRedux({ key: 'login_loading', value: false }))
    if (res.data.status == true) {
      localStorage.setItem('token', res.data.accessToken)
      localStorage.setItem('refreshToken', res.data.refreshToken)
      localStorage.setItem('name', res.data.name)
      localStorage.setItem('email', res.data.email)
      localStorage.setItem('phone', res.data.phone)
      localStorage.setItem('user_logged', true)
      dispatch(updateRedux({ key: 'user_logged', value: true }))
      if (callback) {
        callback()
      }
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: 'login_loading', value: false }))
    console.log(err)
  })
}

export const listProperty = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: 'list_property_loading', value: true }))
  axios.post(`${API_BASE_URL}v1/listProperty`, data).then(res => {
    dispatch(updateRedux({ key: 'list_property_loading', value: false }))
    if (res.data.status == true) {
      if (callback) {
        callback()
      }
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const careerEnquiry = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: 'join_team_loading', value: true }))
  axiosFormInstance.post(`${API_BASE_URL}v1/careerEnquiry`, formData).then(res => {
    dispatch(updateRedux({ key: 'join_team_loading', value: false }))
    if (res.data.status == true) {
      if (callback) {
        callback()
      }
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const registerInterest = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: 'register_interest_loading', value: true }))
  axiosInstance.post(`${API_BASE_URL}v1/registerInterest`, data).then(res => {
    dispatch(updateRedux({ key: 'register_interest_loading', value: false }))
    if (res.data.status == true) {
      if (callback) {
        callback()
      }
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const contactEnquiry = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: 'contact_loading', value: true }))
  axiosInstance.post(`${API_BASE_URL}v1/contactEnquiry`, data).then(res => {
    dispatch(updateRedux({ key: 'contact_loading', value: false }))
    if (res.data.status == true) {
      if (callback) {
        callback()
      }
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const addWishList = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}v1/addWishList`, data).then(res => {
    if (res.data.status == true) {
      dispatch(getWishListCount())
      if (callback) {
        callback()
      }
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const deleteWishList = (data, callback) => (dispatch) => {
  axiosInstance.post(`${API_BASE_URL}v1/deleteWishList`, data).then(res => {
    if (res.data.status == true) {
      dispatch(getWishListCount())
      if (callback) {
        callback()
      }
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const getWishList = (formData, callback) => (dispatch) => {
  console.log(formData)
  dispatch(updateRedux({ key: 'loading_spinner', value: true }))
  axiosFormInstance.post(`${API_BASE_URL}v1/getWishList`, formData).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'loading_spinner', value: false }))
      dispatch(updateRedux({ key: 'wishListData', value: res.data.property_details }))
      dispatch(updateRedux({ key: 'wishListPageCount', value: res.data.pages }))
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const getWishListCount = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}v1/getWishListCount`).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'wishListCount', value: res.data.wishListCount }))
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const getCareerTitles = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}v1/getCareerTitles`).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'jobtitles', value: res.data.data }))
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const allSearchResults = (data) => (dispatch) => {
  dispatch(updateRedux({ key: 'loading_spinner', value: true }))
  axiosInstance.post(`${API_BASE_URL}v1/all-search`, data).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'loading_spinner', value: false }))
      dispatch(updateRedux({ key: 'searchItems_sale', value: res.data.data?.salePropertyList }))
      dispatch(updateRedux({ key: 'searchItems_rent', value: res.data.data?.rentPropertyList }))
      dispatch(updateRedux({ key: 'searchItems_guide', value: res.data.data?.guideList }))
      dispatch(updateRedux({ key: 'searchItems_team', value: res.data.data?.teamList }))
      dispatch(updateRedux({ key: 'searchItems_career', value: res.data.data?.careerList }))
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const forgotPassword = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: 'forgot_loading', value: true }))
  axios.post(`${API_BASE_URL}v1/forgetPassword`, data).then(res => {
    dispatch(updateRedux({ key: 'forgot_loading', value: false }))
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'otp_state', value: true }))
      if (callback) {
        callback()
      }
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => {
    dispatch(updateRedux({ key: 'forgot_loading', value: false }))
    console.log(err)
  })
}


export const verifyPasswordOtp = (data, callback) => (dispatch) => {
  axios.post(`${API_BASE_URL}v1/verifyPasswordOtp`, data).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'otp_state', value: false }))
      dispatch(updateRedux({ key: 'otp_verified', value: true }))
      if (callback) {
        callback()
      }
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const resetPassword = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: 'reset_loading', value: true }))
  axios.post(`${API_BASE_URL}v1/resetPassword`, data).then(res => {
    dispatch(updateRedux({ key: 'reset_loading', value: false }))
    if (res.data.status == true) {
      localStorage.setItem('token', res.data.accessToken)
      localStorage.setItem('user_logged', true)
      dispatch(updateRedux({ key: 'user_logged', value: true }))
      dispatch(updateRedux({ key: 'otp_state', value: false }))
      dispatch(updateRedux({ key: 'otp_verified', value: false }))
      if (callback) {
        callback()
      }
      toast.success(res.data.message, { position: "bottom-center", autoClose: 3000 })
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const getLocationByProperty = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}v1/getLocationByProperty`).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'location_details', value: res.data.location_details }))
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const getPropertyByLocation = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}v1/getPropertyByLocation/${id}`).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'location_property_details', value: res.data.property_details }))
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const getPropertyTypesForSearch = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}v1/property-types-for-filter`).then(res => {
    if (res.data.status == true) {

      dispatch(updateRedux({ key: 'property_types', value: res.data.propertTypeList }))
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}
