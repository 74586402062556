import React from 'react'
import posterImage from "../assets/image/posterimage.png";

function PreLoader() {
    return (
        <div id="preloader" style={{backgroundImage:"url('../assets/image/posterimage.png')"}}>
        </div>
    )
}

export default PreLoader
