import React from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import featured from '../assets/image/featured.png';
import rightArrowWhite from '../assets/image/right_arrow_white.svg';
import { Link } from 'react-router-dom';
import { webURL } from '../constants/configuration';
import { banner_path, general_path } from '../redux/commonReducer';
import { useSelector } from 'react-redux';

const FeaturedSection = ({ details }) => {
  // console.log('aslam',details?.property[0]?._id)
  const generalPath = useSelector(general_path)
  const bannerPath = useSelector(banner_path);


  let image = details?.property?.length && details?.property[0].property_banner_image

  return (
    <div className='featured-section' data-aos="fade-up">
      <LazyLoadImage src={webURL + bannerPath + '/' + image} className='featured-image w-100' effect='blur' />
      <div className='featured-text'>
        <b className='featured-title'>{details?.section_title}</b>
        <div className='featured-title-underline'></div>
        <h2 className='featured-main-title'>{details?.title}</h2>
        <b className='featured-sub-title'>{details?.subtitle}</b>
        <Link to={`/property-details/${details?.property[0]?.slug}`}>
          <div className='view-property-button'>
            <b className='view-property-text text-white'>VIEW PROPERTY</b>
            <img src={rightArrowWhite} width={20} />
          </div>
        </Link>
      </div>
    </div>
  )
}

export default FeaturedSection