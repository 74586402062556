import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import rightArrowWhite from "../assets/image/right_arrow_white.svg";
import { Link, useNavigate } from "react-router-dom";
import { getGuide } from "../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { guide_lists, guide_path } from "../redux/commonReducer";
import { webURL } from "../constants/configuration";

const GuideSection = ({ details }) => {
  const dispatch = useDispatch();
  const GuideItems = useSelector(guide_lists);
  const guidePath = useSelector(guide_path);

  // useEffect(() => {
  //   dispatch(getGuide())
  // }, [])

  return (
    <div className="guide-section">
      <h2 className="our-guide-text">GUIDE</h2>
      <div className="featured-title-underline"></div>
      <Container>
        <div className="prima__guides">
          {details?.map((ele, i) => {
            return (
              <Link to={`${"/guide_details/" + ele?.slug}`}>
                <div className="guide__grid" key={i}>
                  <img
                    src={webURL + guidePath + "/" + ele?.guide_image}
                    alt=""
                  />
                  <h3>{ele?.guide_title}</h3>
                </div>
              </Link>
            );
          })}
        </div>
      </Container>
      <Link to="/guide">
        <div className="read__more">
          <div className="text-center mt-5">
            <button className="btn btn-primary">
              Read All
              <img src={rightArrowWhite} />
            </button>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default GuideSection;
